import { GoogleTokenResponse } from "@kuika/kuika-cl-model";
import Axios, { AxiosResponse } from "axios";
import LocalStorageService from "../auth/local-storage-service";
import { IChangeTwoFactorAuthenticationType } from "../models/dto/IChangeTwoFactorAuthenticationType.dto";
import { IChangePasswordDto } from "../models/dto/change-password.dto";
import { ICreateUserWithPasswordDto } from "../models/dto/create-user-with-password";
import { ICreateUserDto } from "../models/dto/createuser";
import { IForgotPasswordDto } from "../models/dto/forgotpassword.dto";
import { IExternalTokenRequest, ILoginDto } from "../models/dto/login.dto";
import { IResetPasswordWithTokenDto } from "../models/dto/reset-password-with-token";
import { ISignupDto } from "../models/dto/signup.dto";
import { IUpdateUserStartingScreen } from "../models/dto/update-user-starting-screen.dto";
import { IUpdateUsernameDto } from "../models/dto/update-username.dto";
import { IUserInfoDto } from "../models/dto/user-info.dto";
import { IUserRoleDto } from "../models/dto/userrole.dto";
import { IVerifyVerificationCodeDto } from "../models/dto/verify-verification-code.dto";
import { CheckResultType } from "../models/enum/auth";
import { KuikaAppManager } from "../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../shared/utilty/main-functions";
import HelperService from "./helper-service";

const backendUrl = KuikaAppManager.GetBackendUrl();

declare let window: any;
export class UserService {
  public static async Login(user: ILoginDto): Promise<AxiosResponse> {
    try {
      const res = await Axios.post<ILoginDto, AxiosResponse>(`${backendUrl}/auth/login`, user);
      const result = UserService.ProcessLoginResult(res);
      return await result;
    } catch (error: any) {
      if (
        error?.response?.data?.message === "Your password has expired" ||
        error?.response?.data?.message === "Şifrenizin süresi doldu" ||
        error?.response?.data?.message === "Votre mot de passe a expiré"
      ) {
        throw new Error(error.response.data.message);
      }

      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async EncryptedLogin(user: ILoginDto): Promise<AxiosResponse> {
    try {
      const res = await Axios.post<ILoginDto, AxiosResponse>(`${backendUrl}/auth/encryptedlogin`, user);
      const result = UserService.ProcessLoginResult(res);
      return await result;
    } catch (error: any) {
      if (
        error?.response?.data?.message === "Your password has expired" ||
        error?.response?.data?.message === "Şifrenizin süresi doldu" ||
        error?.response?.data?.message === "Votre mot de passe a expiré"
      ) {
        throw new Error(error.response.data.message);
      }

      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async LoginWithVerificationCode(user: any): Promise<AxiosResponse> {
    try {
      const res = await Axios.post<ILoginDto, AxiosResponse>(`${backendUrl}/auth/loginwithverificationcode`, user);
      LocalStorageService.setVerificationStatus("true");
      LocalStorageService.setVerificationUserName("");
      const result = UserService.ProcessLoginResult(res);
      return await result;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async LoginWithExternalToken(externalTokenRequest: IExternalTokenRequest): Promise<AxiosResponse> {
    try {
      const res = await Axios.post<ILoginDto, AxiosResponse>(`${backendUrl}/auth/loginwithtoken`, externalTokenRequest);
      const result = UserService.ProcessLoginResult(res);
      return await result;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  private static async ProcessLoginResult(res: AxiosResponse<any>): Promise<AxiosResponse> {
    if (res && res.status === 200) {
      if (res.data.status === 2) {
        return res;
      }
      LocalStorageService.setToken(res.data);
      LocalStorageService.setUserLanguage(res.data);
      LocalStorageService.setLoginStatus(res.data.status);
      if (window.userInfo === undefined) {
        if (res.data.status === 0) {
          await UserService.GetUserInfo();
        }
      }

      res.data?.passwordValidationResult?.checkResults?.forEach((result) => {
        if (result.resultType === CheckResultType.Warning) {
          KMainFunctions.displayWarningNotification(result.message);
        }
      });
    }

    return res;
  }

  public static async SignUp(user: ISignupDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<ISignupDto, AxiosResponse>(`${backendUrl}/auth/signup`, user);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async VerifyVerificationCode(obj: IVerifyVerificationCodeDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<IChangePasswordDto, AxiosResponse>(`${backendUrl}/auth/isverificationcodevalid`, obj);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ResetPasswordWithToken(user: IResetPasswordWithTokenDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<ILoginDto, AxiosResponse>(`${backendUrl}/auth/resetpasswordwithtoken`, user);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async CreateUser(user: ICreateUserDto): Promise<string> {
    try {
      await Axios.post<ICreateUserDto, AxiosResponse>(`${backendUrl}/auth/createuser`, user);
      return "";
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      if (error && error.response && error.response.data) {
        return error.response.data.message;
      }
      return "An error has been occurred. Please try again.";
    }
  }

  public static async CreateUserWithPassword(user: ICreateUserWithPasswordDto): Promise<string> {
    try {
      await Axios.post<ICreateUserWithPasswordDto, AxiosResponse>(`${backendUrl}/auth/createuserwithpassword`, user);
      return "";
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      if (error && error.response && error.response.data) {
        return error.response.data.message;
      }
      return "An error has been occurred. Please try again.";
    }
  }

  public static async ForgotPassword(user: IForgotPasswordDto): Promise<AxiosResponse> {
    try {
      const result = await Axios.post<IForgotPasswordDto, AxiosResponse>(`${backendUrl}/auth/forgotpassword`, user);
      LocalStorageService.setForgotPasswordEmail(user.email);
      return result;
    } catch (error: any) {
      LocalStorageService.setForgotPasswordEmail("");
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ResetPassword(user: IResetPasswordWithTokenDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<IResetPasswordWithTokenDto, AxiosResponse>(
        `${backendUrl}/auth/resetpasswordwithtoken`,
        user
      );
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static getEmailToResetPassword(): any {
    return LocalStorageService.getForgotPasswordEmail();
  }

  public static async Logout() {
    try {
      var request = indexedDB.open("msalDb", 1);
      request.onupgradeneeded = function (event: any) {
        var db = event.target.result;
        var objectStore = db.createObjectStore("logingout", { keyPath: "id" });
        objectStore.add({ id: 1, status: "true" });
      };
      await Axios.post<ILoginDto, AxiosResponse>(`${backendUrl}/auth/logout`);
      LocalStorageService.clearToken();
      window.userInfo = undefined;
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
    }
  }

  public static async ChangePassword(obj: IChangePasswordDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<IChangePasswordDto, AxiosResponse>(`${backendUrl}/auth/changepassword`, obj);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async GetUserInfo(): Promise<IUserInfoDto> {
    if (!window?.userInfo?.username) {
      try {
        const res = await Axios.get<null, AxiosResponse>(`${backendUrl}/auth/userinfo`);
        if (res && res.status === 200) {
          window.userInfo = res.data as IUserInfoDto;
        }
      } catch (error) {
        window.userInfo = undefined;

        if (error?.response?.status && error.response.status !== 401 && error.response.status >= 400) {
          LocalStorageService.clearToken();
          HelperService.gotoLogin();
        }

        // KMainFunctions.exceptionHandler(error);
        return error;
      }
    }
    return window.userInfo as IUserInfoDto;
  }

  public static async GetUserInfoForce(): Promise<any> {
    try {
      const res = await Axios.get<null, AxiosResponse>(`${backendUrl}/auth/userinfo`);
      if (res && res.status === 200) {
        if (res?.data?.userLogoutMessage?.length > 0) {
          KMainFunctions.displayWarningNotification(res?.data?.userLogoutMessage);
          setTimeout(() => {
            UserService.Logout();
          }, 3000);
          return;
        }
        window.userInfo = res.data;
        LocalStorageService.setCurrentDomain(res.data.lastUsedDomainName);
        return window.userInfo;
      }
    } catch (error: any) {
      window.userInfo = undefined;
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async AddUserToRole(obj: IUserRoleDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<IUserRoleDto, AxiosResponse>(`${backendUrl}/authorization/addusertorole`, obj);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async RemoveUserFromRole(obj: IUserRoleDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<IUserRoleDto, AxiosResponse>(`${backendUrl}/authorization/removeuserfromrole`, obj);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async EnableUser(userName: string): Promise<AxiosResponse> {
    try {
      return await Axios.put<null, AxiosResponse>(`${backendUrl}/auth/enableuser`, { email: userName });
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async DisableUser(userName: string): Promise<AxiosResponse> {
    try {
      return await Axios.put<null, AxiosResponse>(`${backendUrl}/auth/disableuser`, { email: userName });
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async UpdateUserName(obj: IUpdateUsernameDto): Promise<AxiosResponse> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/auth/updateusername`, obj);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async UpdateUserStartingScreen(obj: IUpdateUserStartingScreen): Promise<AxiosResponse> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/auth/updateuserstartingscreen`, obj);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ValidateToken(): Promise<AxiosResponse> {
    try {
      return await Axios.post<null, AxiosResponse>(`${backendUrl}/auth/validatetoken`);
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async AuthenticateWithGoogle(authCode: string): Promise<AxiosResponse<GoogleTokenResponse>> {
    try {
      return await Axios.post<null, AxiosResponse<GoogleTokenResponse>>(`${backendUrl}/auth/authenticatewithgoogle`, {
        authCode,
        redirectUri: location.origin
      });
    } catch (error: any) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async SendVerificationCode(obj: IForgotPasswordDto) {
    try {
      return await Axios.post<IForgotPasswordDto, AxiosResponse>(`${backendUrl}/auth/sendverificationcode`, obj);
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }

  public static async ChangeTwoFactorAuthenticationType(obj: IChangeTwoFactorAuthenticationType) {
    try {
      return await Axios.post<AxiosResponse>(`${backendUrl}/auth/changetwofactorauthenticationtype`, obj);
    } catch (error) {
      KMainFunctions.exceptionHandler(error);
      return error;
    }
  }
}
