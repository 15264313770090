import { Spin, Typography } from "antd";
import { connect } from "react-redux";
import {
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/photo-take/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { SiskonMaster_Screen } from "../siskonmaster/siskonmaster";
import { LandingPage_ScreenBase } from "./landingpage-base";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;

class LandingPage_Screen extends LandingPage_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <SiskonMaster_Screen
          form={this.props.form}
          initialValues={{}}
          screenInputs={this.props.screenInputs}
          setMasterPageInitLoaded={this.setMasterPageInitLoaded}
          setMasterPageRecurringLoaded={this.setMasterPageRecurringLoaded}
          isMasterPageInitLoaded={this.state.isMasterPageInitLoaded}
          isMasterPageRecurringLoaded={this.state.isMasterPageRecurringLoaded}
        >
          <Spin spinning={this.state.spinnerCount > 0}>
            <div
              id="landingpage_body"
              style={
                {
                  backgroundColor: "rgba(247, 247, 249, 1)",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundPosition: "left",
                  display: "block",
                  overflow: "auto",
                  minHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  maxHeight: "calc(100vh - 60px - 60px - 0px - 0px)",
                  height: "calc(100vh - 60px - 60px - 0px - 0px)"
                } as any
              }
            ></div>
          </Spin>
        </SiskonMaster_Screen>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that))
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(LandingPage_Screen)))))
);
export { tmp as LandingPage_Screen };
//export default tmp;
//export { tmp as LandingPage_Screen };
