import React from "react";
import { define } from "remount";
import { IAppMode, IAuthType } from "./bootstrapper";
import { IRouterType } from "./routes/router";

const authType: IAuthType = "Kuika";
const routerType: IRouterType = "HashRouter";
const appMode: IAppMode = "WebComponent";

export default () => {
  const AppBootstrapper = React.lazy(() =>
    import("./bootstrapper").then((module) => ({ default: module.AppBootstrapper }))
  );

  const WebComponent = ({ external_user, external_access_token, external_refresh_token }) => {
    return (
      <AppBootstrapper
        authType={authType}
        routerType={routerType}
        tagName="siskon-aiestimater-ai-estimator"
        external_user={external_user}
        external_access_token={external_access_token}
        external_refresh_token={external_refresh_token}
        appMode={appMode}
      />
    );
  };
  define(
    {
      "siskon-aiestimater-ai-estimator": {
        component: WebComponent,
        attributes: ["external_user", "external_access_token", "external_refresh_token"]
      }
    },
    {
      shadow: false,
      retarget: true,
      quiet: true
    }
  );
};
