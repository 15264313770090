import { FormInstance } from "antd/lib/form";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRequirement_List_Backup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRequirement_List_Backup_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  GetProject: any[];
  GetProject_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  GetProjectRequirements: any[];
  GetProjectRequirements_dummy: any[];
  GetProjectRequirementCount: any[];
  GetProjectRequirementCount_dummy: any[];
  ChangeVisibilityOf: string;
  ChangeVisibilityOf_dummy: string;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  RequirementValidation: any[];
  RequirementValidation_dummy: any[];
  GetInvalidProjectRequirementCount: any[];
  GetInvalidProjectRequirementCount_dummy: any[];
  DeleteProjectRequirement: number;
  DeleteProjectRequirement_dummy: number;
  SaveRecord: number;
  SaveRecord_dummy: number;
  isComp114888Visible: "visible" | "hidden";
  isComp876881Visible: "visible" | "hidden";
  isComp915146Visible: "visible" | "hidden";
  isComp177090Visible: "visible" | "hidden";
  isComp388298Visible: "visible" | "hidden";
  isComp150995Visible: "visible" | "hidden";
  isComp950205Visible: "visible" | "hidden";
  isComp149030Visible: "visible" | "hidden";
  isComp622874Visible: "visible" | "hidden";
  isComp508571Visible: "visible" | "hidden";
  isComp404757Visible: "visible" | "hidden";
  isComp200014Visible: "visible" | "hidden";
  isComp374347Visible: "visible" | "hidden";
  isComp274056Visible: "visible" | "hidden";
}

export class Requirement_List_Backup_ScreenBase extends React.PureComponent<IRequirement_List_Backup_ScreenProps, any> {
  requirement_list_backup_882849_value_kuikaTableRef: React.RefObject<any>;
  requirement_list_backup_872730_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "6a64801e-b3f8-43ff-a483-02e9e0464709",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 10993, PropertyName: "value", Value: "-" },
        { Id: 556871, PropertyName: "value", Value: "Gereksinim Ekle" },
        { Id: 679003, PropertyName: "label", Value: "Gereksinim Ekle CSV" },
        { Id: 766218, PropertyName: "label", Value: "Metrik Konfigürasyonu" },
        { Id: 734003, PropertyName: "label", Value: "Tüm Satırları Kontrol Et" },
        { Id: 994409, PropertyName: "label", Value: "Yeni Gereksinim" },
        { Id: 114888, PropertyName: "value", Value: "Gereksinim Validasyonu Yapılamadı Hatalı Veri Bulunmakta." },
        { Id: 876881, PropertyName: "value", Value: "Validasyon Başarılı" },
        { Id: 915146, PropertyName: "value", Value: "0" },
        { Id: 177090, PropertyName: "value", Value: "Hata Bulundu." },
        { Id: 812288, PropertyName: "placeholder", Value: "Arama..." },
        { Id: 882849, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 582846, PropertyName: "title", Value: "Kategori" },
        { Id: 793542, PropertyName: "value", Value: "[datafield:category]" },
        { Id: 206006, PropertyName: "title", Value: "Gereksinim" },
        { Id: 151277, PropertyName: "value", Value: "[datafield:requirement]" },
        { Id: 150995, PropertyName: "title", Value: "Interaction" },
        { Id: 230670, PropertyName: "value", Value: "[datafield:interaction]" },
        { Id: 950205, PropertyName: "title", Value: "Communication" },
        { Id: 227961, PropertyName: "value", Value: "[datafield:communication]" },
        { Id: 149030, PropertyName: "title", Value: "Process" },
        { Id: 236732, PropertyName: "value", Value: "[datafield:eventprocess]" },
        { Id: 622874, PropertyName: "title", Value: "Total EP" },
        { Id: 457942, PropertyName: "value", Value: "[datafield:totalep]" },
        { Id: 508571, PropertyName: "title", Value: "Entry" },
        { Id: 97250, PropertyName: "value", Value: "[datafield:entry]" },
        { Id: 404757, PropertyName: "title", Value: "Read" },
        { Id: 986519, PropertyName: "value", Value: "[datafield:cosmicread]" },
        { Id: 200014, PropertyName: "title", Value: "Write" },
        { Id: 235616, PropertyName: "value", Value: "[datafield:cosmicwrite]" },
        { Id: 374347, PropertyName: "title", Value: "Exit" },
        { Id: 117824, PropertyName: "value", Value: "[datafield:cosmicexit]" },
        { Id: 274056, PropertyName: "title", Value: "Toplam CFP" },
        { Id: 725379, PropertyName: "value", Value: "[datafield:totalcfp]" },
        { Id: 69614, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.requirement_list_backup_882849_value_kuikaTableRef = React.createRef();
    this.requirement_list_backup_872730_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      SetValueOf: "",
      GetProject: [],
      MyContext: [],
      GetProjectRequirements: [],
      GetProjectRequirementCount: [],
      ChangeVisibilityOf: "",
      NAVIGATE: "",
      RequirementValidation: [],
      GetInvalidProjectRequirementCount: [],
      DeleteProjectRequirement: 0,
      SaveRecord: false,
      isComp114888Visible: "hidden",
      isComp876881Visible: "hidden",
      isComp915146Visible: "hidden",
      isComp177090Visible: "hidden",
      isComp388298Visible: "hidden",
      isComp150995Visible: "hidden",
      isComp950205Visible: "hidden",
      isComp149030Visible: "hidden",
      isComp622874Visible: "hidden",
      isComp508571Visible: "hidden",
      isComp404757Visible: "hidden",
      isComp200014Visible: "hidden",
      isComp374347Visible: "hidden",
      isComp274056Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("requirement_list_backup", "requirement_list_backup");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Requirement_List_BackupPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("requirement_list_backup", "requirement_list_backup");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("requirement_list_backup", "requirement_list_backup");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Requirement_List_BackupPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      requirement_list_backup_915146_value: ReactSystemFunctions.translate(this.ml, 915146, "value", this.defaultML)
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Requirement_List_BackupPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "requirement_list_backup_388298_value",
      false,
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupPageInit1_();
    });

    return isErrorOccurred;
  };
  Requirement_List_BackupPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Validation_2: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      ),
      ProjectID_3: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupPageInit1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetProject = result?.data.getProject;
    formVars.requirement_list_backup_968593_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.projectName : null
    );
    stateVars.isComp150995Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp950205Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149030Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp622874Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp508571Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404757Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicRead : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200014Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374347Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicExit : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274056Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.MyContext = result?.data.myContext;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    stateVars.GetProjectRequirementCount = result?.data.getProjectRequirementCount;
    formVars.requirement_list_backup_856615_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProjectRequirementCount?.length > 0 ? stateVars.GetProjectRequirementCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupPageInit2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp114888Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp876881Visible", "hidden");
    formVars.requirement_list_backup_968593_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.projectName : null
    );

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;
    stateVars.isComp150995Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp950205Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp149030Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp622874Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp508571Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp404757Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicRead : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp200014Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp374347Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicExit : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp274056Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_list_backup_872730_total = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirementCount?.length > 0 ? this.state.GetProjectRequirementCount[0]?.count : null
    );

    formVars.requirement_list_backup_856615_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProjectRequirementCount?.length > 0 ? this.state.GetProjectRequirementCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_618962_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_679003_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Requirement_CSV_Mapping_Form",
      "ProjectID",
      this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Requirement_List_Backup",
      "Requirement_CSV_Mapping_Form",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_766218_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Project_Form",
      "ProjectID",
      this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid
    );
    KuikaAppManager.addToPageInputVariables(
      "Project_Form",
      "CompanyID",
      ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null)
    );
    KuikaAppManager.addToPageInputVariables("Project_Form", "ScreenType", "Metric");

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Requirement_List_Backup",
      "Project_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "649029",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_734003_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "requirement_list_backup_388298_value",
      true,
      null
    );
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp114888Visible", "hidden");
    stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(this, "isComp876881Visible", "hidden");

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_734003_onClick1_();
    });

    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Validation_1: ReactSystemFunctions.convertToTypeByName(true, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_734003_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.RequirementValidation = result?.data.requirementValidation;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_734003_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RequirementValidation?.length > 0 ? this.state.RequirementValidation[0]?.count : null
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp114888Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick3_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.RequirementValidation?.length > 0 ? this.state.RequirementValidation[0]?.count : null
        ),
        "0"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp876881Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick4_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName("", "string"),
      Validation_0: ReactSystemFunctions.convertToTypeByName(1, "number")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_734003_onClick4_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetInvalidProjectRequirementCount = result?.data.getInvalidProjectRequirementCount;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_734003_onClick5_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.GetInvalidProjectRequirementCount?.length > 0
            ? this.state.GetInvalidProjectRequirementCount[0]?.invalidRowCount
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp915146Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick6_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick6_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.GetInvalidProjectRequirementCount?.length > 0
            ? this.state.GetInvalidProjectRequirementCount[0]?.invalidRowCount
            : null
        ),
        "1"
      )
    ) {
      stateVars.ChangeVisibilityOf = await ReactSystemFunctions.changeVisibilityOf(
        this,
        "isComp177090Visible",
        "visible"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick7_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick7_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isGreaterOrEqual(
        ReactSystemFunctions.value(
          this,
          this.state.GetInvalidProjectRequirementCount?.length > 0
            ? this.state.GetInvalidProjectRequirementCount[0]?.invalidRowCount
            : null
        ),
        "1"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_list_backup_915146_value",
        ReactSystemFunctions.toString(
          this,
          this.state.GetInvalidProjectRequirementCount?.length > 0
            ? this.state.GetInvalidProjectRequirementCount[0]?.invalidRowCount
            : null
        ),
        null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick8_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_List_BackupComponent_734003_onClick8_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_734003_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_994409_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Requirement_Form",
      "ProjectID",
      this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid
    );
    KuikaAppManager.addToPageInputVariables("Requirement_Form", "RequirementID", null);

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Requirement_List_Backup",
      "Requirement_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "972953",
      null,
      null,
      "False",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_812288_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Validation_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_388298_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      ),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_812288_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    stateVars.GetProjectRequirementCount = result?.data.getProjectRequirementCount;
    formVars.requirement_list_backup_856615_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProjectRequirementCount?.length > 0 ? stateVars.GetProjectRequirementCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_812288_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_812288_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;
    formVars.requirement_list_backup_872730_total = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirementCount?.length > 0 ? this.state.GetProjectRequirementCount[0]?.count : null
    );

    formVars.requirement_list_backup_856615_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProjectRequirementCount?.length > 0 ? this.state.GetProjectRequirementCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_76935_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Requirement_Form",
      "ProjectID",
      this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid
    );
    KuikaAppManager.addToPageInputVariables(
      "Requirement_Form",
      "RequirementID",
      ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Requirement_List_Backup",
      "Requirement_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "825632",
      null,
      null,
      "False",
      "800px",
      "70vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_114728_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectRequirementID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      ),
      ProjectID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e2b36e50_659b_5c6c_fe89_93c5347b4325_confirmation",
        this.defaultML,
        "Silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Requirement_List_Backup/Requirement_List_BackupComponent_114728_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.DeleteProjectRequirement = result?.data.deleteProjectRequirement;
        stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

        stateVars.GetProjectRequirementCount = result?.data.getProjectRequirementCount;
        formVars.requirement_list_backup_856615_value = ReactSystemFunctions.toString(
          this,
          stateVars.GetProjectRequirementCount?.length > 0 ? stateVars.GetProjectRequirementCount[0]?.count : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Requirement_List_BackupComponent_114728_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_114728_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;
    formVars.requirement_list_backup_872730_total = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirementCount?.length > 0 ? this.state.GetProjectRequirementCount[0]?.count : null
    );

    formVars.requirement_list_backup_856615_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProjectRequirementCount?.length > 0 ? this.state.GetProjectRequirementCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_360874_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_360874_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_1;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_360874_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_360874_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_863456_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_863456_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_2;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_863456_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_863456_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_699678_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_699678_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_699678_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_699678_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_21953_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_21953_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_4;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_21953_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_21953_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_147672_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_147672_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_5;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_147672_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_147672_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_994336_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_994336_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_6;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_994336_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_994336_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_57159_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_57159_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_7;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_57159_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_57159_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_556455_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_556455_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_8;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_556455_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_556455_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_856200_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_856200_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_9;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_856200_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_856200_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_720826_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_720826_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_10;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_720826_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_720826_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_404877_onPressEnter = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "requirement_list_backup_882849_value", ".id"),
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_category", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_communication", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicExit", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicRead", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_cosmicWrite", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_entry", "value", "", "", "")),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_eventProcess", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_interaction", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_requirement", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "Table_882849_totalCFP", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "Table_882849_totalEP", "value", "", "", "")),
        "number"
      ),
      Validation_1: ReactSystemFunctions.convertToTypeByName(false, "boolean"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName("", "string"),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_404877_onPressEnter",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_11;
    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_404877_onPressEnter1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_404877_onPressEnter1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_List_BackupComponent_872730_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Validation_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_388298_value", "value", "", "", "")
        ),
        "boolean"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_812288_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_list_backup_872730_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_List_Backup/Requirement_List_BackupComponent_872730_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetProjectRequirements = result?.data.getProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_List_BackupComponent_872730_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_List_BackupComponent_872730_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_882849 = this.state.GetProjectRequirements;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [649029, 972953, 825632] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Requirement_List_BackupPageInit();
    }
  }
}
