import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IModel_Training_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IModel_Training_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GetStatusDropDown: any[];
  GetStatusDropDown_dummy: any[];
  MyContext: any[];
  MyContext_dummy: any[];
  GetModels: any[];
  GetModels_dummy: any[];
  GetModelCount: any[];
  GetModelCount_dummy: any[];
  GetConfigFromFile: any;
  GetConfigFromFile_dummy: any;
  DeleteModel: number;
  DeleteModel_dummy: number;
  Train: any;
  Train_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Model_Training_List_ScreenBase extends React.PureComponent<IModel_Training_List_ScreenProps, any> {
  model_training_list_164601_value_kuikaSelectBoxRef: React.RefObject<any>;
  model_training_list_795226_value_kuikaTableRef: React.RefObject<any>;
  model_training_list_658618_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "77ca573a-7621-4066-89ef-47fd8077b532",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 958134, PropertyName: "value", Value: "Model Eğitim İşlemleri" },
        { Id: 551893, PropertyName: "label", Value: "Ekle" },
        { Id: 933893, PropertyName: "label", Value: "Yenile" },
        { Id: 945581, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 795226, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 809316, PropertyName: "title", Value: "Model Adı" },
        { Id: 979789, PropertyName: "value", Value: "[datafield:modelname]" },
        { Id: 143445, PropertyName: "title", Value: "Metrik" },
        { Id: 112340, PropertyName: "value", Value: "[datafield:metricname]" },
        { Id: 228361, PropertyName: "title", Value: "Proje Kodu" },
        { Id: 884182, PropertyName: "value", Value: "[datafield:projectcode]" },
        { Id: 758027, PropertyName: "title", Value: "Proje Adı" },
        { Id: 922220, PropertyName: "value", Value: "[datafield:projectname]" },
        { Id: 39612, PropertyName: "title", Value: "Durum" },
        { Id: 789441, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 313383, PropertyName: "title", Value: "Baz Model" },
        { Id: 105826, PropertyName: "value", Value: "[datafield:parentmodel]" },
        { Id: 176956, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 806157, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 412285, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 977403, PropertyName: "value", Value: "[datafield:endtime]" },
        { Id: 217898, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.model_training_list_164601_value_kuikaSelectBoxRef = React.createRef();
    this.model_training_list_795226_value_kuikaTableRef = React.createRef();
    this.model_training_list_658618_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      GetStatusDropDown: [],
      MyContext: [],
      GetModels: [],
      GetModelCount: [],
      GetConfigFromFile: "",
      DeleteModel: 0,
      Train: "",
      Notify: false,

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("model_training_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Model_Training_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("model_training_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("model_training_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Model_Training_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Model_Training_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  Model_Training_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Model_Training_List/Model_Training_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.IsUserDisabled?.length > 0 ? this.state.IsUserDisabled[0]?.result : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Model_Training_ListPageInit2_,
        "Model_Training_List",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.Model_Training_ListPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      Status_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      searchfor_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Model_Training_List/Model_Training_ListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetStatusDropDown = result?.data.getStatusDropDown;

    formVars.model_training_list_164601_options = stateVars.GetStatusDropDown;
    stateVars.MyContext = result?.data.myContext;
    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0 ? stateVars.GetModelCount[0]?.modelCount : null
    );
    stateVars.GetConfigFromFile = result?.data.getConfigFromFile;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_164601 = this.state.GetStatusDropDown;
    stateVars.dataSource_164601 = this.state.GetStatusDropDown;

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Model_Training_ListComponent_551893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Model_Form", "ModelID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Model_Training_List",
      "Model_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "511494",
      null,
      null,
      "true",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_933893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_933893_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0 ? stateVars.GetModelCount[0]?.modelCount : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListComponent_933893_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_933893_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_164601_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_164601_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0 ? stateVars.GetModelCount[0]?.modelCount : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListComponent_164601_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_164601_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_945581_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_945581_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    stateVars.GetModelCount = result?.data.getModelCount;
    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetModelCount?.length > 0 ? stateVars.GetModelCount[0]?.modelCount : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListComponent_945581_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_945581_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_155052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Model_Form",
      "ModelID",
      ReactSystemFunctions.value(this, "model_training_list_795226_value", ".id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Model_Training_List",
      "Model_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "792729",
      null,
      null,
      "true",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_485487_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", ".id"),
        "Guid"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "id"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      Status_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "3f0ca7d0_bc31_7c0d_bc1b_9d66a6c94ad9_confirmation",
        this.defaultML,
        "Silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Model_Training_List/Model_Training_ListComponent_485487_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.DeleteModel = result?.data.deleteModel;
        stateVars.GetModels = result?.data.getModels;

        stateVars.GetModelCount = result?.data.getModelCount;
        formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
          this,
          stateVars.GetModelCount?.length > 0 ? stateVars.GetModelCount[0]?.modelCount : null
        );
        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Model_Training_ListComponent_485487_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_485487_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetModels;
    formVars.model_training_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    formVars.model_training_list_924845_value = ReactSystemFunctions.toString(
      this,
      this.state.GetModelCount?.length > 0 ? this.state.GetModelCount[0]?.modelCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_805575_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      InModelName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelName"),
        "string"
      ),
      OutModelName_0: ReactSystemFunctions.convertToTypeByName("Out Model", "string"),
      ProjectName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "projectName"),
        "string"
      ),
      TargetMetric_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "metricName"),
        "string"
      ),
      UserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "projectID"),
        "Guid"
      ),
      ModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "model_training_list_795226_value", "modelID"),
        "Guid"
      ),
      BaseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetConfigFromFile?.baseUrl),
        "string"
      ),
      TrainingPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetConfigFromFile?.trainingPath),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_805575_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Train = result?.data.train;
    stateVars.GetModels = result?.data.getModels;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListComponent_805575_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_805575_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.Train?.status), "success")) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "c7e59176_5c30_9f94_e915_562e9bf5f25b_notify",
          this.defaultML,
          "Eğitim başlatıldı."
        ),
        "success",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Model_Training_ListComponent_805575_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Model_Training_ListComponent_805575_onClick2_();
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_805575_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, this.state.Train?.status), "success")) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "da1c9417_0fe5_584c_b62d_808bfbd7ad12_notify",
          this.defaultML,
          "Bir hata oluştu."
        ),
        "success",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Model_Training_ListComponent_805575_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Model_Training_ListComponent_805575_onClick3_();
      });
    }

    return isErrorOccurred;
  };
  Model_Training_ListComponent_805575_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetModels;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_658618_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "model_training_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "model_training_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Model_Training_List/Model_Training_ListComponent_658618_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetModels = result?.data.getModels;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Model_Training_ListComponent_658618_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Model_Training_ListComponent_658618_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetModels;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Model_Training_ListComponent_795226_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 511494, 792729] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Model_Training_ListPageInit();
    }
  }
}
