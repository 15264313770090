import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRequirement_CSV_Mapping_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRequirement_CSV_Mapping_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  GetProject: any[];
  GetProject_dummy: any[];
  UploadFile: any;
  UploadFile_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;
  IfThenElse: boolean;
  IfThenElse_dummy: boolean;
  GetCSVDataAsXML: string;
  GetCSVDataAsXML_dummy: string;
  GetCSVColumns: any[];
  GetCSVColumns_dummy: any[];
  ChangeEnabledOf: any;
  ChangeEnabledOf_dummy: any;
  GetCSVData: any[];
  GetCSVData_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  ImportRequirementCSVData: number;
  ImportRequirementCSVData_dummy: number;
  isComp729170Visible: "visible" | "hidden";
  isComp764235Visible: "visible" | "hidden";
  isComp683017Visible: "visible" | "hidden";
  isComp857030Visible: "visible" | "hidden";
  isComp422954Visible: "visible" | "hidden";
  isComp954696Visible: "visible" | "hidden";
  isComp880703Visible: "visible" | "hidden";
  isComp546272Visible: "visible" | "hidden";
  isComp360472Visible: "visible" | "hidden";
  isComp778607Visible: "visible" | "hidden";
  isComp31693Visible: "visible" | "hidden";
  isComp447760Visible: "visible" | "hidden";
  isComp708674Visible: "visible" | "hidden";
  isComp79040Visible: "visible" | "hidden";
  isComp955618Visible: "visible" | "hidden";
  isComp552112Visible: "visible" | "hidden";
  isComp877818Visible: "visible" | "hidden";
  isComp271661Visible: "visible" | "hidden";
  isComp507913Enabled: "enabled" | "disabled";
}

export class Requirement_CSV_Mapping_Form_ScreenBase extends React.PureComponent<
  IRequirement_CSV_Mapping_Form_ScreenProps,
  any
> {
  requirement_csv_mapping_form_103151_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_257064_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_329446_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_997086_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_765747_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_605125_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_81144_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_367677_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_977106_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_373616_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_882586_value_kuikaSelectBoxRef: React.RefObject<any>;
  requirement_csv_mapping_form_68366_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "b4aacb4f-b2b6-468f-9cea-fdaed9f40988",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 758880, PropertyName: "value", Value: "-" },
        { Id: 624379, PropertyName: "value", Value: "Gereksinim Ekle CSV" },
        { Id: 989536, PropertyName: "value", Value: "Kategori" },
        { Id: 571059, PropertyName: "value", Value: "Gereksinimler" },
        { Id: 270139, PropertyName: "value", Value: "Event" },
        { Id: 998964, PropertyName: "value", Value: "interaction" },
        { Id: 130721, PropertyName: "value", Value: "Communication" },
        { Id: 557991, PropertyName: "value", Value: "Process" },
        { Id: 666553, PropertyName: "value", Value: "Toplam EP" },
        { Id: 821478, PropertyName: "value", Value: "Cosmic" },
        { Id: 782871, PropertyName: "value", Value: "Entry" },
        { Id: 269127, PropertyName: "value", Value: "Read" },
        { Id: 331974, PropertyName: "value", Value: "Write" },
        { Id: 740128, PropertyName: "value", Value: "Exit" },
        { Id: 68155, PropertyName: "value", Value: "Toplam CFP" },
        { Id: 440190, PropertyName: "label", Value: "Ön İzleme" },
        { Id: 73867, PropertyName: "label", Value: "Dosya Seç" },
        { Id: 68366, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 758129, PropertyName: "title", Value: "Kategori" },
        { Id: 300407, PropertyName: "value", Value: "[datafield:category]" },
        { Id: 548936, PropertyName: "title", Value: "Gereksinim" },
        { Id: 201284, PropertyName: "value", Value: "[datafield:requirement]" },
        { Id: 778607, PropertyName: "title", Value: "Interaction" },
        { Id: 914541, PropertyName: "value", Value: "[datafield:interaction]" },
        { Id: 31693, PropertyName: "title", Value: "Communication" },
        { Id: 7616, PropertyName: "value", Value: "[datafield:communication]" },
        { Id: 447760, PropertyName: "title", Value: "Process" },
        { Id: 679092, PropertyName: "value", Value: "[datafield:process]" },
        { Id: 708674, PropertyName: "title", Value: "TotalEP" },
        { Id: 386141, PropertyName: "value", Value: "[datafield:eventtotalep]" },
        { Id: 79040, PropertyName: "title", Value: "Entry" },
        { Id: 506629, PropertyName: "value", Value: "[datafield:entry]" },
        { Id: 955618, PropertyName: "title", Value: "Read" },
        { Id: 699922, PropertyName: "value", Value: "[datafield:read]" },
        { Id: 552112, PropertyName: "title", Value: "Write" },
        { Id: 483838, PropertyName: "value", Value: "[datafield:write]" },
        { Id: 877818, PropertyName: "title", Value: "Exit" },
        { Id: 851387, PropertyName: "value", Value: "[datafield:exit]" },
        { Id: 271661, PropertyName: "title", Value: "TotalCFP" },
        { Id: 943264, PropertyName: "value", Value: "[datafield:cosmictotalep]" },
        { Id: 306657, PropertyName: "label", Value: "Kaydet" },
        { Id: 636116, PropertyName: "label", Value: "İptal" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.requirement_csv_mapping_form_103151_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_257064_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_329446_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_997086_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_765747_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_605125_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_81144_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_367677_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_977106_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_373616_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_882586_value_kuikaSelectBoxRef = React.createRef();
    this.requirement_csv_mapping_form_68366_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      GetProject: [],
      UploadFile: "",
      IfThenElse: false,
      GetCSVDataAsXML: "",
      GetCSVColumns: [],
      ChangeEnabledOf: "",
      Notify: false,
      GetCSVData: [],
      SetValueOf: "",
      ImportRequirementCSVData: 0,
      isComp729170Visible: "hidden",
      isComp764235Visible: "hidden",
      isComp683017Visible: "hidden",
      isComp857030Visible: "hidden",
      isComp422954Visible: "hidden",
      isComp954696Visible: "hidden",
      isComp880703Visible: "hidden",
      isComp546272Visible: "hidden",
      isComp360472Visible: "hidden",
      isComp778607Visible: "hidden",
      isComp31693Visible: "hidden",
      isComp447760Visible: "hidden",
      isComp708674Visible: "hidden",
      isComp79040Visible: "hidden",
      isComp955618Visible: "hidden",
      isComp552112Visible: "hidden",
      isComp877818Visible: "hidden",
      isComp271661Visible: "hidden",
      isComp507913Enabled: "enabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("requirement_csv_mapping_form", "requirement_csv_mapping_form");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Requirement_CSV_Mapping_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("requirement_csv_mapping_form", "requirement_csv_mapping_form");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("requirement_csv_mapping_form", "requirement_csv_mapping_form");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Requirement_CSV_Mapping_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Requirement_CSV_Mapping_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormPageInit",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetProject = result?.data.getProject;
    formVars.requirement_csv_mapping_form_214015_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.projectName : null
    );
    stateVars.isComp729170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp764235Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp683017Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp857030Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422954Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp954696Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicRead : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp880703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp546272Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicExit : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp360472Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp778607Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31693Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp447760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp708674Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalEP : null),
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalEP : null)
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp79040Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicRead : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552112Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp877818Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicExit : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271661Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit2_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit_if2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormPageInit_else2 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit_else21_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit_else2f1 = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Notify = await ReactSystemFunctions.notify(
      this,
      ReactSystemFunctions.translateCustomActions(
        this.ml,
        "e1ec9ad0_3899_6ed1_5d06_50b195f74ae8_notify",
        this.defaultML,
        "Dosya uzantısı csv olmalı."
      ),
      "danger",
      "bottom-right",
      3
    );
    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormPageInit_else21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")
    ) {
      isErrorOccurred = await this.Requirement_CSV_Mapping_FormPageInit_else2f1();
      if (isErrorOccurred) return true;
    } else {
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), null)) {
      isErrorOccurred = await this.Requirement_CSV_Mapping_FormPageInit_if2();
      if (isErrorOccurred) return true;
    } else {
      isErrorOccurred = await this.Requirement_CSV_Mapping_FormPageInit_else2();
      if (isErrorOccurred) return true;
    }

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit3_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormPageInit3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetCSVDataAsXML = result?.data.getCSVDataAsXML;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit4_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CSVText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetCSVDataAsXML),
        "string"
      )
    };

    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormPageInit4_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetCSVColumns = result?.data.getCSVColumns;

      formVars.requirement_csv_mapping_form_103151_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_257064_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_329446_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_997086_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_765747_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_605125_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_81144_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_367677_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_977106_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_373616_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_882586_options = stateVars.GetCSVColumns;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit5_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requirement_csv_mapping_form_214015_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.projectName : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit6_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit7_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit7_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_103151 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit8_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit8_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_103151 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit9_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit9_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit10_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit10_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_257064 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit11_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit11_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_257064 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit12_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit12_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp729170Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit13_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit14_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit14_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_329446 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit15_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit15_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_329446 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit16_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit16_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp764235Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit17_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit18_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit18_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_997086 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit19_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit19_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_997086 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit20_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit20_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp683017Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit21_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit22_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit22_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_765747 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit23_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit23_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_765747 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit24_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit24_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp857030Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit25_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit26_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit26_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_605125 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit27_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit27_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_605125 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit28_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit28_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp422954Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit29_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit30_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit30_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_81144 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit31_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit31_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_81144 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit32_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit32_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit32_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp954696Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicRead : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit33_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit33_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit34_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit34_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit34_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_367677 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit35_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit35_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit35_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_367677 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit36_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit36_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit36_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp880703Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit37_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit37_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit38_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit38_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit38_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_977106 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit39_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit39_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit39_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_977106 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit40_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit40_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit40_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp546272Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicExit : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit41_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit41_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit42_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit42_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit42_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_373616 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit43_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit43_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit43_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_373616 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit44_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit44_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit44_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp360472Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormPageInit45_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit45_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit46_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit46_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit46_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_882586 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit47_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit47_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit47_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.UploadFile?.type), "text/csv")) {
      stateVars.dataSource_882586 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit48_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormPageInit48_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormPageInit48_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.isComp778607Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp31693Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp447760Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp708674Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalEP : null),
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalEP : null)
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp79040Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp955618Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicRead : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp552112Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp877818Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicExit : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp271661Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormComponent_846558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormComponent_81144_onBlur = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_81144_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        null
      )
    ) {
      KuikaAppManager.showSpinner(this);

      stateVars.ChangeEnabledOf = await ReactSystemFunctions.changeEnabledOf(this, "isComp507913Enabled", "enabled");

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormComponent_440190_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      KuikaAppManager.showSpinner(this);

      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "49f95ea5_f280_179e_21e9_d35120336fe2_notify",
          this.defaultML,
          "Gösterilecek veri bulunmamakta"
        ),
        "danger",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_440190_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_440190_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_440190_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_257064_value",
            "value",
            "GetCSVColumns",
            "columnID",
            ""
          )
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "requirement_csv_mapping_form_257064_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CSVText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetCSVDataAsXML),
        "string"
      ),
      CategoryColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_103151_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      RequirementColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_257064_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      InteractionColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_329446_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      CommunicationColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_997086_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      ProcessColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_765747_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      EventTotalEPColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_605125_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      EntryColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_81144_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      ReadColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_367677_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      WriteColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_977106_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      ExitColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_373616_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      CosmicTotalEPColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_882586_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormComponent_440190_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetCSVData = result?.data.getCSVData;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_440190_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_440190_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_440190_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_440190_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_440190_onClick3_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_440190_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_68366 = this.state.GetCSVData;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormComponent_73867_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.UploadFile = ((await ReactSystemFunctions.uploadFileWithInfo()) as any).data;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_CSV_Mapping_FormComponent_73867_onClick1_();
    });

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      value_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.UploadFile?.data),
        "string"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormComponent_73867_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetCSVDataAsXML = result?.data.getCSVDataAsXML;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_103151_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick3_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_257064_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick4_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_329446_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick5_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick5_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick5_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_997086_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick6_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick6_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick6_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_765747_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick7_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick7_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick7_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_605125_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick8_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick8_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick8_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_81144_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick9_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick9_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick9_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_367677_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick10_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick10_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick10_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_977106_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick11_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick11_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick11_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_373616_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick12_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick12_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick12_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
        this,
        "requirement_csv_mapping_form_882586_value",
        "",
        null,
        "ColumnID"
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick13_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick13_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick13_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CSVText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetCSVDataAsXML),
        "string"
      )
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormComponent_73867_onClick13_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.GetCSVColumns = result?.data.getCSVColumns;

      formVars.requirement_csv_mapping_form_103151_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_257064_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_329446_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_997086_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_765747_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_605125_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_81144_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_367677_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_977106_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_373616_options = stateVars.GetCSVColumns;

      formVars.requirement_csv_mapping_form_882586_options = stateVars.GetCSVColumns;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick14_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick14_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick14_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick15_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick15_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick15_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_103151 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick16_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick16_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick16_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_103151 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick17_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick17_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick17_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick18_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick18_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick18_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_257064 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick19_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick19_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick19_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_257064 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick20_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick20_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick20_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick21_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick21_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick21_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_329446 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick22_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick22_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick22_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_329446 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick23_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick23_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick23_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick24_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick24_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick24_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_997086 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick25_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick25_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick25_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_997086 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick26_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick26_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick26_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick27_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick27_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick27_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_765747 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick28_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick28_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick28_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_765747 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick29_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick29_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick29_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick30_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick30_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick30_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_605125 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick31_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick31_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick31_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_605125 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick32_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick32_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick32_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick33_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick33_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick33_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_81144 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick34_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick34_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick34_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_81144 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick35_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick35_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick35_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick36_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick36_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick36_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_367677 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick37_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick37_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick37_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_367677 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick38_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick38_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick38_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick39_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick39_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick39_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_977106 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick40_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick40_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick40_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_977106 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick41_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick41_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick41_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick42_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick42_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick42_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_373616 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick43_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick43_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick43_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_373616 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick44_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick44_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick44_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick45_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick45_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick45_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_882586 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick46_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_73867_onClick46_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_73867_onClick46_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.dataSource_882586 = this.state.GetCSVColumns;

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormComponent_306657_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      KuikaAppManager.showSpinner(this);

      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "a5c5af63_9cc2_140d_1844_a49cd30593c7_notify",
          this.defaultML,
          "Kaydedilecek veri bulunmamakta"
        ),
        "danger",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_306657_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_306657_onClick1_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_306657_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_257064_value",
            "value",
            "GetCSVColumns",
            "columnID",
            ""
          )
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "requirement_csv_mapping_form_257064_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectId_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      CSVText_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetCSVDataAsXML),
        "string"
      ),
      CategoryColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_103151_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      RequirementColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_257064_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      InteractionColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_329446_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      CommunicationColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_997086_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      ProcessColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_765747_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      EventTotalEPColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_605125_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      EntryColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_81144_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      ReadColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_367677_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      WriteColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_977106_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      ExitColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_373616_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      CosmicTotalEPColID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "requirement_csv_mapping_form_882586_value",
            "value",
            "GetCSVColumns",
            "columnID",
            "columnID"
          )
        ),
        "number"
      ),
      Username_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Requirement_CSV_Mapping_Form/Requirement_CSV_Mapping_FormComponent_306657_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.ImportRequirementCSVData = result?.data.importRequirementCSVData;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_306657_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_306657_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_306657_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (ReactSystemFunctions.isNotEmpty(ReactSystemFunctions.toString(this, this.state.UploadFile?.data), null)) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "ea79f455_cc53_91e1_8c06_96a99d116727_notify",
          this.defaultML,
          "Gereksinimler başarıyla kaydedildi"
        ),
        "success",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_306657_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Requirement_CSV_Mapping_FormComponent_306657_onClick3_();
      });
    }

    return isErrorOccurred;
  };
  Requirement_CSV_Mapping_FormComponent_306657_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_CSV_Mapping_FormComponent_636116_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
