import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUserListFront_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUserListFront_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetUsersFront: any[];
  GetUsersFront_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  DisableUser: any;
  DisableUser_dummy: any;
  SetValueOf: any;
  SetValueOf_dummy: any;
  isComp429211Enabled: "enabled" | "disabled";

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class UserListFront_ScreenBase extends React.PureComponent<IUserListFront_ScreenProps, any> {
  userlistfront_5520259_value_kuikaTableRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "fb026c50-2f83-4afe-9fd5-f6cab59c30e2",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 651166, PropertyName: "value", Value: "Kullanıcı İşlemleri" },
        { Id: 105155, PropertyName: "label", Value: "Kullanıcı Ekle" },
        { Id: 5520259, PropertyName: "nodatafoundmessage", Value: "Kullanıcı bulunamadı." },
        { Id: 8047211, PropertyName: "title", Value: "E-Posta" },
        { Id: 7359078, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 6147183, PropertyName: "title", Value: "Ad" },
        { Id: 8998609, PropertyName: "value", Value: "[datafield:firstname]" },
        { Id: 6995627, PropertyName: "title", Value: "Soyad" },
        { Id: 8027997, PropertyName: "value", Value: "[datafield:lastname]" },
        { Id: 590404, PropertyName: "title", Value: "Şirket" },
        { Id: 899693, PropertyName: "value", Value: "[datafield:companyname]" },
        { Id: 239095, PropertyName: "title", Value: "Rol" },
        { Id: 22534, PropertyName: "value", Value: "[datafield:rolename]" },
        { Id: 576286, PropertyName: "title", Value: "Aktif" },
        { Id: 725823, PropertyName: "title", Value: "Sil" },
        { Id: 352395, PropertyName: "value", Value: "label" },
        { Id: 937118, PropertyName: "label", Value: "Button text" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.userlistfront_5520259_value_kuikaTableRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetUsersFront: [],
      NAVIGATE: "",
      DisableUser: "",
      SetValueOf: "",
      isComp429211Enabled: "disabled",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("userlistfront", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.UserListFrontPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("userlistfront", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SYSTEM ADMIN", "COMPANY ADMIN"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("userlistfront", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UserListFrontPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.UserListFrontPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      userlistfront_352395_value: ReactSystemFunctions.translate(this.ml, 352395, "value", this.defaultML)
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  UserListFrontPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserListFront/UserListFrontPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFront = result?.data.getUsersFront;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListFrontPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListFrontPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5520259 = this.state.GetUsersFront;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UserListFrontComponent_105155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UserForm", "userId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserListFront",
      "UserForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "171468",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListFrontComponent_2324188_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserForm",
      "userId",
      ReactSystemFunctions.value(this, "userlistfront_5520259_value", ".id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserListFront",
      "UserForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "616460",
      null,
      null,
      "False",
      "800px",
      "573px",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListFrontComponent_553212_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "63a7d744_7623_5acf_d5db_4fdb52282218_confirmation",
        this.defaultML,
        "Kullanıcıyı silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);

        stateVars.DisableUser = await ReactSystemFunctions.disableUser(
          ReactSystemFunctions.value(this, "userlistfront_5520259_value", ".userName")
        );

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.UserListFrontComponent_553212_onClick1_();
        });
      }
    });

    return isErrorOccurred;
  };
  UserListFrontComponent_553212_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "UserListFront/UserListFrontComponent_553212_onClick1_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetUsersFront = result?.data.getUsersFront;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListFrontComponent_553212_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListFrontComponent_553212_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5520259 = this.state.GetUsersFront;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListFrontComponent_937118_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(
      this,
      "userlistfront_352395_value",
      ReactSystemFunctions.toString(
        this,
        ReactSystemFunctions.value(this, "userlistfront_5520259_value", "selectedValues", "GetUsersFront", "", "")
      ),
      null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [171468, 616460] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UserListFrontPageInit();
    }
  }
}
