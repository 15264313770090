import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPrediction_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPrediction_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetPrediction: any[];
  GetPrediction_dummy: any[];
  GetProjectsDropdown: any[];
  GetProjectsDropdown_dummy: any[];
  GetMetricsDropdownByMetod: any[];
  GetMetricsDropdownByMetod_dummy: any[];
  GetModelsDropdown: any[];
  GetModelsDropdown_dummy: any[];
  GetProject: any[];
  GetProject_dummy: any[];
  GetPredictionMetric: any[];
  GetPredictionMetric_dummy: any[];
  SetValueOf: any;
  SetValueOf_dummy: any;
  SaveRecord: number;
  SaveRecord_dummy: number;
}

export class Prediction_Form_ScreenBase extends React.PureComponent<IPrediction_Form_ScreenProps, any> {
  prediction_form_257064_value_kuikaSelectBoxRef: React.RefObject<any>;
  prediction_form_152678_value_kuikaSelectBoxRef: React.RefObject<any>;
  prediction_form_617831_value_kuikaSelectBoxRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "f909dc66-e8c6-4ce6-91bd-bd4c159a00ce",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 520608, PropertyName: "value", Value: "Büyüklük Kestirimi Gerçekleştirme (Tahminleme)" },
        { Id: 571059, PropertyName: "value", Value: "Proje" },
        { Id: 74156, PropertyName: "value", Value: "Metod" },
        { Id: 66204, PropertyName: "value", Value: "Metric" },
        { Id: 319065, PropertyName: "value", Value: "Baz Model" },
        { Id: 306657, PropertyName: "label", Value: "Kaydet" },
        { Id: 636116, PropertyName: "label", Value: "İptal" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.prediction_form_257064_value_kuikaSelectBoxRef = React.createRef();
    this.prediction_form_152678_value_kuikaSelectBoxRef = React.createRef();
    this.prediction_form_617831_value_kuikaSelectBoxRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GoBack: "",
      MyContext: [],
      GetPrediction: [],
      GetProjectsDropdown: [],
      GetMetricsDropdownByMetod: [],
      GetModelsDropdown: [],
      GetProject: [],
      GetPredictionMetric: [],
      SetValueOf: "",
      SaveRecord: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("prediction_form", "prediction_form");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Prediction_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("prediction_form", "prediction_form");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("prediction_form", "prediction_form");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Prediction_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      prediction_form_257064_value: this.state.GetProject?.at?.(0)?.id ?? undefined,
      prediction_form_152678_value: this.state.GetPredictionMetric?.at?.(0)?.metricName ?? undefined,
      prediction_form_617831_value: this.state.GetPrediction?.at?.(0)?.modelID ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Prediction_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      PredictionID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionID ?? this.props.screenInputs.predictionid,
        "Guid"
      ),
      Type_3: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_form_791258_value", "value", "", "", "")
        ),
        "string"
      ),
      ProjectID_5: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      PredictionID_6: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionID ?? this.props.screenInputs.predictionid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Prediction_Form/Prediction_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetPrediction = result?.data.getPrediction;
    formVars.prediction_form_617831_value =
      stateVars.GetPrediction?.length > 0 ? stateVars.GetPrediction[0]?.modelID : null;
    formVars.prediction_form_617831_options = stateVars.GetModelsDropdown;
    stateVars.GetProjectsDropdown = result?.data.getProjectsDropdown;

    formVars.prediction_form_257064_value = stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.id : null;
    formVars.prediction_form_257064_options = stateVars.GetProjectsDropdown;
    stateVars.GetMetricsDropdownByMetod = result?.data.getMetricsDropdownByMetod;

    formVars.prediction_form_152678_value =
      stateVars.GetPredictionMetric?.length > 0 ? stateVars.GetPredictionMetric[0]?.metricName : null;
    formVars.prediction_form_152678_options = stateVars.GetMetricsDropdownByMetod;
    stateVars.GetModelsDropdown = result?.data.getModelsDropdown;

    formVars.prediction_form_617831_value =
      stateVars.GetPrediction?.length > 0 ? stateVars.GetPrediction[0]?.modelID : null;
    formVars.prediction_form_617831_options = stateVars.GetModelsDropdown;
    stateVars.GetProject = result?.data.getProject;
    formVars.prediction_form_257064_value = stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.id : null;
    formVars.prediction_form_257064_options = stateVars.GetProjectsDropdown;
    stateVars.GetPredictionMetric = result?.data.getPredictionMetric;
    formVars.prediction_form_152678_value =
      stateVars.GetPredictionMetric?.length > 0 ? stateVars.GetPredictionMetric[0]?.metricName : null;
    formVars.prediction_form_152678_options = stateVars.GetMetricsDropdownByMetod;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_FormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.GetPredictionMetric?.length > 0 ? this.state.GetPredictionMetric[0]?.metodName : null
        ),
        "Event"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "prediction_form_791258_value", "1", null);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Prediction_FormPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Prediction_FormPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  Prediction_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.toString(
          this,
          this.state.GetPredictionMetric?.length > 0 ? this.state.GetPredictionMetric[0]?.metodName : null
        ),
        "Cosmic"
      )
    ) {
      stateVars.SetValueOf = await ReactSystemFunctions.setValueOf(this, "prediction_form_791258_value", "0", null);

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Prediction_FormPageInit3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Prediction_FormPageInit3_();
      });
    }

    return isErrorOccurred;
  };
  Prediction_FormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.prediction_form_257064_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.id : null
    );

    stateVars.dataSource_257064 = this.state.GetProjectsDropdown;
    stateVars.dataSource_257064 = this.state.GetProjectsDropdown;
    formVars.prediction_form_152678_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPredictionMetric?.length > 0 ? this.state.GetPredictionMetric[0]?.metricName : null
    );

    stateVars.dataSource_152678 = this.state.GetMetricsDropdownByMetod;
    stateVars.dataSource_152678 = this.state.GetMetricsDropdownByMetod;
    formVars.prediction_form_617831_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPrediction?.length > 0 ? this.state.GetPrediction[0]?.modelID : null
    );

    stateVars.dataSource_617831 = this.state.GetModelsDropdown;
    stateVars.dataSource_617831 = this.state.GetModelsDropdown;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Prediction_FormComponent_846558_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_FormComponent_791258_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Type_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_form_791258_value", "value", "", "", "")
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_Form/Prediction_FormComponent_791258_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetMetricsDropdownByMetod = result?.data.getMetricsDropdownByMetod;

    formVars.prediction_form_152678_options = stateVars.GetMetricsDropdownByMetod;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_FormComponent_791258_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_FormComponent_791258_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_152678 = this.state.GetMetricsDropdownByMetod;
    stateVars.dataSource_152678 = this.state.GetMetricsDropdownByMetod;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_FormComponent_306657_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "prediction_form_617831_value", "value", "GetModelsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "prediction_form_617831_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "prediction_form_257064_value", "value", "GetProjectsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "prediction_form_257064_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionID ?? this.props.screenInputs.predictionid,
        "Guid"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_form_617831_value", "value", "GetModelsDropdown", "id", "id")
        ),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_form_257064_value", "value", "GetProjectsDropdown", "id", "id")
        ),
        "Guid"
      ),
      StatusID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          this.state.GetPrediction?.length > 0 ? this.state.GetPrediction[0]?.statusID : null
        ),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.GetPrediction?.length > 0 ? this.state.GetPrediction[0]?.id : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_Form/Prediction_FormComponent_306657_onClick",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Prediction_FormComponent_306657_onClick1_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Prediction_FormComponent_306657_onClick1_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_FormComponent_306657_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "prediction_form_617831_value", "value", "GetModelsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "prediction_form_617831_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "prediction_form_257064_value", "value", "GetProjectsDropdown", "id", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "prediction_form_257064_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionID ?? this.props.screenInputs.predictionid,
        "Guid"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ModelID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_form_617831_value", "value", "GetModelsDropdown", "id", "id")
        ),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_form_257064_value", "value", "GetProjectsDropdown", "id", "id")
        ),
        "Guid"
      ),
      StatusID_0: ReactSystemFunctions.convertToTypeByName("b28263af-1fa5-4c08-9ef2-edc0ba526d75", "Guid"),
      CreateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, this.state.GetPrediction?.length > 0 ? this.state.GetPrediction[0]?.id : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Prediction_Form/Prediction_FormComponent_306657_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_2;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Prediction_FormComponent_306657_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Prediction_FormComponent_306657_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_FormComponent_306657_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.PredictionID ?? this.props.screenInputs.predictionid,
        "Guid"
      ),
      MetricID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_form_152678_value",
            "value",
            "GetMetricsDropdownByMetod",
            "metricID",
            "metricID"
          )
        ),
        "Guid"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_Form/Prediction_FormComponent_306657_onClick2_",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.SaveRecord = result?.data.saveRecord_3;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_FormComponent_306657_onClick3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_FormComponent_306657_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_FormComponent_636116_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
