const screenInputData = {
  Model_Training_Form: [{"name":"UserID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ProjectName","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"InModelName","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"MetricName","type":"String","cardinality":1,"isExpandedPanel":null},{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ModelID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Requirement_Form: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"RequirementID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Requirement_CSV_Mapping_Form: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Company_Form: [{"name":"Id","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Project_Copy_Form: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CompanyID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"NewProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Project_Form: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"CompanyID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"ScreenType","type":"String","cardinality":1,"isExpandedPanel":null}],
	Model_Form: [{"name":"ModelID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Requirement_List: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Prediction_Form: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null},{"name":"PredictionID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Requirement_List_Backup: [{"name":"ProjectID","type":"Guid","cardinality":1,"isExpandedPanel":null}],
	Project_List: [{"name":"ModalHeight","type":"String","cardinality":1,"isExpandedPanel":null}],
	UserForm: [{"name":"userId","type":"Guid","cardinality":1,"isExpandedPanel":null}]
};

export class ScreenInputHelper {
  public static getScreenInputValue(screenName: string, inputName: string, value: string): any {
    switch (this.getScreenInputType(screenName, inputName)) {
      case "String":
        return value;
      case "Boolean":
        return value.toLowerCase() === "true";
      // TODO
    }

    return value;
  }

  private static getScreenInputType(screenName: string, inputName: string): string {
    const data = screenInputData[screenName]?.find((x) => x.Name === inputName);
    return data?.TypeName ? data.TypeName : "";
  }
}
