import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ICompany_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ICompany_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  GetKUserID: any[];
  GetKUserID_dummy: any[];
  InvertBit: any[];
  InvertBit_dummy: any[];
  SaveRecord: number;
  SaveRecord_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  GetCompany: any[];
  GetCompany_dummy: any[];
  IsCompanyActive: any[];
  IsCompanyActive_dummy: any[];
  isComp829855Visible: "visible" | "hidden";
}

export class Company_Form_ScreenBase extends React.PureComponent<ICompany_Form_ScreenProps, any> {
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "5293b014-0b32-4b92-b0a3-012849dad826",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 9544, PropertyName: "label", Value: "Kaydet" },
        { Id: 860086, PropertyName: "label", Value: "İptal" },
        { Id: 796540, PropertyName: "value", Value: "Firma Detay" },
        { Id: 853915, PropertyName: "value", Value: "Firma Kodu" },
        { Id: 330914, PropertyName: "value", Value: "Firma Adı" },
        { Id: 121147, PropertyName: "value", Value: "Aktif" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      GetKUserID: [],
      InvertBit: [],
      SaveRecord: false,
      GoBack: "",
      GetCompany: [],
      IsCompanyActive: [],
      isComp829855Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("company_form", "company_form");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Company_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("company_form", "company_form");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("company_form", "company_form");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Company_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      company_form_879971_value: this.state.GetCompany?.at?.(0)?.companyCode ?? undefined,
      company_form_651118_value: this.state.GetCompany?.at?.(0)?.companyName ?? undefined,
      company_form_853053_value: this.state.IsCompanyActive?.at?.(0)?.isActive ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Company_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Company_Form/Company_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetCompany = result?.data.getCompany;
    formVars.company_form_879971_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompany?.length > 0 ? stateVars.GetCompany[0]?.companyCode : null
    );
    formVars.company_form_651118_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetCompany?.length > 0 ? stateVars.GetCompany[0]?.companyName : null
    );
    stateVars.isComp829855Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, stateVars.GetCompany?.length > 0 ? stateVars.GetCompany[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Company_FormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Company_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      CompanyId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        "Guid"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(this, "Company_Form/Company_FormPageInit1_", localVar);
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.IsCompanyActive = result?.data.isCompanyActive;
      formVars.company_form_853053_value = ReactSystemFunctions.toBoolean(
        this,
        stateVars.IsCompanyActive?.length > 0 ? stateVars.IsCompanyActive[0]?.isActive : null
      );
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Company_FormPageInit2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Company_FormPageInit2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Company_FormPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.company_form_879971_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.companyCode : null
    );

    formVars.company_form_651118_value = ReactSystemFunctions.toString(
      this,
      this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.companyName : null
    );

    stateVars.isComp829855Visible =
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        null
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Company_FormPageInit3_();
    });

    return isErrorOccurred;
  };
  Company_FormPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        null
      )
    ) {
      formVars.company_form_853053_value = ReactSystemFunctions.toBoolean(
        this,
        this.state.IsCompanyActive?.length > 0 ? this.state.IsCompanyActive[0]?.isActive : null
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
      KuikaAppManager.hideSpinner(this);
    } else {
      KuikaAppManager.hideSpinner(this);
    }

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Company_FormComponent_9544_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Company_Form/Company_FormComponent_9544_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUserID = result?.data.getKUserID;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Company_FormComponent_9544_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Company_FormComponent_9544_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      BitValue_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(
          this,
          ReactSystemFunctions.value(this, "company_form_853053_value", "value", "", "", "")
        ),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Company_Form/Company_FormComponent_9544_onClick1_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.InvertBit = result?.data.invertBit;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Company_FormComponent_9544_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Company_FormComponent_9544_onClick2_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Company_FormComponent_9544_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_651118_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "company_form_651118_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_879971_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "company_form_879971_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      CompanyName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_651118_value", "value", "", "", "")
        ),
        "string"
      ),
      CompanyCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_879971_value", "value", "", "", "")
        ),
        "string"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetKUserID?.length > 0 ? this.state.GetKUserID[0]?.id : null),
        "Guid"
      ),
      IsDisabled_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toBoolean(this, this.state.InvertBit?.length > 0 ? this.state.InvertBit[0]?.result : null),
        "boolean"
      )
    };

    if (
      ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Company_Form/Company_FormComponent_9544_onClick2_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_1;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Company_FormComponent_9544_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Company_FormComponent_9544_onClick3_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Company_FormComponent_9544_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_651118_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "company_form_651118_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_879971_value", "value", "", "", "")
        ),
        null
      ),
      message: "Boş geçilemez.",
      formName: "company_form_879971_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      Id_0: ReactSystemFunctions.convertToTypeByName(this.props.screenInputs.Id ?? this.props.screenInputs.id, "Guid"),
      CompanyName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_651118_value", "value", "", "", "")
        ),
        "string"
      ),
      CompanyCode_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "company_form_879971_value", "value", "", "", "")
        ),
        "string"
      ),
      LastUpdateDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date"),
      LastUpdateUserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.GetKUserID?.length > 0 ? this.state.GetKUserID[0]?.id : null),
        "Guid"
      ),
      CreatedDatetime_0: ReactSystemFunctions.convertToTypeByName(Date(), "Date")
    };

    if (
      ReactSystemFunctions.isEmpty(
        ReactSystemFunctions.value(this, this.state.GetCompany?.length > 0 ? this.state.GetCompany[0]?.id : null),
        null
      )
    ) {
      if (true) {
        KuikaAppManager.showSpinner(this);
      }
      let result = await KuikaAppManager.ApiRequest(
        this,
        "Company_Form/Company_FormComponent_9544_onClick3_",
        localVar
      );
      if (!KMainFunctions.IsResStatus2XX(result)) return true;

      stateVars.SaveRecord = result?.data.saveRecord_2;
      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Company_FormComponent_9544_onClick4_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Company_FormComponent_9544_onClick4_();
      });
    }

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Company_FormComponent_9544_onClick4_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Company_FormComponent_860086_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Company_FormComponent_968274_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
