import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IPrediction_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IPrediction_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetPredictions: any[];
  GetPredictions_dummy: any[];
  GetPredictionCount: any[];
  GetPredictionCount_dummy: any[];
  GetStatusDropDown: any[];
  GetStatusDropDown_dummy: any[];
  GetConfigFromFile: any;
  GetConfigFromFile_dummy: any;
  DeletePrediction: number;
  DeletePrediction_dummy: number;
  MakePredicition: any;
  MakePredicition_dummy: any;
  Notify: boolean;
  Notify_dummy: boolean;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Prediction_List_ScreenBase extends React.PureComponent<IPrediction_List_ScreenProps, any> {
  prediction_list_164601_value_kuikaSelectBoxRef: React.RefObject<any>;
  prediction_list_795226_value_kuikaTableRef: React.RefObject<any>;
  prediction_list_658618_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "a33897e5-ac96-4845-a5dd-367d4cdcd753",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 958134, PropertyName: "value", Value: "Büyüklük Tahminleme İşlemleri" },
        { Id: 551893, PropertyName: "label", Value: "Ekle" },
        { Id: 9603, PropertyName: "label", Value: "Yenile" },
        { Id: 945581, PropertyName: "placeholder", Value: "Ara..." },
        { Id: 795226, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 943865, PropertyName: "title", Value: "İşlem" },
        { Id: 758027, PropertyName: "title", Value: "Proje Adı" },
        { Id: 922220, PropertyName: "value", Value: "[datafield:projectname]" },
        { Id: 143445, PropertyName: "title", Value: "Metrik" },
        { Id: 112340, PropertyName: "value", Value: "[datafield:metricname]" },
        { Id: 313383, PropertyName: "title", Value: "Baz Model" },
        { Id: 105826, PropertyName: "value", Value: "[datafield:modelname]" },
        { Id: 39612, PropertyName: "title", Value: "Durum" },
        { Id: 789441, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 176956, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 806157, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 412285, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 977403, PropertyName: "value", Value: "[datafield:endtime]" },
        { Id: 372121, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.prediction_list_164601_value_kuikaSelectBoxRef = React.createRef();
    this.prediction_list_795226_value_kuikaTableRef = React.createRef();
    this.prediction_list_658618_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      MyContext: [],
      GetPredictions: [],
      GetPredictionCount: [],
      GetStatusDropDown: [],
      GetConfigFromFile: "",
      DeletePrediction: 0,
      MakePredicition: "",
      Notify: false,

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("prediction_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Prediction_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("prediction_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("prediction_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Prediction_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Prediction_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  Prediction_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Prediction_List/Prediction_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.IsUserDisabled?.length > 0 ? this.state.IsUserDisabled[0]?.result : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Prediction_ListPageInit2_,
        "Prediction_List",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.Prediction_ListPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Prediction_List/Prediction_ListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetPredictions = result?.data.getPredictions;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0 ? stateVars.GetPredictionCount[0]?.count : null
    );
    stateVars.GetStatusDropDown = result?.data.getStatusDropDown;

    formVars.prediction_list_164601_options = stateVars.GetStatusDropDown;
    stateVars.GetConfigFromFile = result?.data.getConfigFromFile;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_164601 = this.state.GetStatusDropDown;
    stateVars.dataSource_164601 = this.state.GetStatusDropDown;

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Prediction_ListComponent_551893_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("Prediction_Form", "ProjectID", "00000000-0000-0000-0000-000000000000");
    KuikaAppManager.addToPageInputVariables("Prediction_Form", "PredictionID", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Prediction_List",
      "Prediction_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "405037",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_9603_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_9603_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictions = result?.data.getPredictions;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0 ? stateVars.GetPredictionCount[0]?.count : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListComponent_9603_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_9603_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_164601_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_164601_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0 ? stateVars.GetPredictionCount[0]?.count : null
    );
    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListComponent_164601_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_164601_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_945581_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_945581_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictionCount = result?.data.getPredictionCount;
    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetPredictionCount?.length > 0 ? stateVars.GetPredictionCount[0]?.count : null
    );
    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListComponent_945581_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_945581_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_155052_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "ProjectID",
      ReactSystemFunctions.value(this, "prediction_list_795226_value", ".projectID")
    );
    KuikaAppManager.addToPageInputVariables(
      "Prediction_Form",
      "PredictionID",
      ReactSystemFunctions.value(this, "prediction_list_795226_value", ".id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Prediction_List",
      "Prediction_Form",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "385032",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_485487_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      PredictionID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", ".id"),
        "Guid"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      CompanyID_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "2bb3b34c_a6f0_a7a3_c60f_0fe2d3a9a067_confirmation",
        this.defaultML,
        "Silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (true) {
          KuikaAppManager.showSpinner(this);
        }
        let result = await KuikaAppManager.ApiRequest(
          this,
          "Prediction_List/Prediction_ListComponent_485487_onClick",
          localVar
        );
        if (!KMainFunctions.IsResStatus2XX(result)) return true;

        stateVars.DeletePrediction = result?.data.deletePrediction;
        stateVars.GetPredictionCount = result?.data.getPredictionCount;
        formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
          this,
          stateVars.GetPredictionCount?.length > 0 ? stateVars.GetPredictionCount[0]?.count : null
        );
        stateVars.GetPredictions = result?.data.getPredictions;

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.Prediction_ListComponent_485487_onClick1_();
        });
      }
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_485487_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetPredictions;
    formVars.prediction_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    formVars.prediction_list_438110_value = ReactSystemFunctions.toString(
      this,
      this.state.GetPredictionCount?.length > 0 ? this.state.GetPredictionCount[0]?.count : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_56837_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "projectID"),
        "Guid"
      ),
      UserId_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "projectName"),
        "string"
      ),
      ModelName_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "modelName"),
        "string"
      ),
      TargetMetric_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "metricName"),
        "string"
      ),
      PredictionID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "prediction_list_795226_value", "id"),
        "Guid"
      ),
      BaseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetConfigFromFile?.baseUrl),
        "string"
      ),
      PredictionPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetConfigFromFile?.predictionPath),
        "string"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_56837_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MakePredicition = result?.data.makePredicition;
    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListComponent_56837_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(ReactSystemFunctions.toString(this, this.state.MakePredicition?.status), "success")
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "f76808fa_6bad_7880_d63b_e387bbd4426b_notify",
          this.defaultML,
          "Tahminleme başlatıldı."
        ),
        "success",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Prediction_ListComponent_56837_onClick2_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Prediction_ListComponent_56837_onClick2_();
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isNotEqualTo(
        ReactSystemFunctions.toString(this, this.state.MakePredicition?.status),
        "success"
      )
    ) {
      stateVars.Notify = await ReactSystemFunctions.notify(
        this,
        ReactSystemFunctions.translateCustomActions(
          this.ml,
          "84ae2861_4fd7_8c9d_f4d7_8266a1e16db2_notify",
          this.defaultML,
          "Bir hata oluştu!"
        ),
        "danger",
        "bottom-right",
        0
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars, () => {
        this.Prediction_ListComponent_56837_onClick3_();
      });
    } else {
      this.setState(stateVars, () => {
        this.Prediction_ListComponent_56837_onClick3_();
      });
    }

    return isErrorOccurred;
  };
  Prediction_ListComponent_56837_onClick3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetPredictions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Prediction_ListComponent_658618_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      searchfor_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_945581_value", "value", "", "", "")
        ),
        "string"
      ),
      Status_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(
            this,
            "prediction_list_164601_value",
            "value",
            "GetStatusDropDown",
            "statusCode",
            "statusCode"
          )
        ),
        "string"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "prediction_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Prediction_List/Prediction_ListComponent_658618_onChange",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetPredictions = result?.data.getPredictions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Prediction_ListComponent_658618_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Prediction_ListComponent_658618_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetPredictions;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 405037, 385032] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Prediction_ListPageInit();
    }
  }
}
