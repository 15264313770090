import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface ISignup_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface ISignup_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  Signup: any;
  Signup_dummy: any;
  AddUserToRole: boolean;
  AddUserToRole_dummy: boolean;
  goToNextScreenAfterSignup: any;
  goToNextScreenAfterSignup_dummy: any;
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  isComp387716Enabled: "enabled" | "disabled";
}

export class Signup_ScreenBase extends React.PureComponent<ISignup_ScreenProps, any> {
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "04f04072-ac7a-4b1f-997c-36e29cf189dc",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 717912, PropertyName: "value", Value: "Hesap oluştur" },
        { Id: 318005, PropertyName: "value", Value: "Ad" },
        { Id: 14020, PropertyName: "value", Value: "Soyad" },
        { Id: 454674, PropertyName: "value", Value: "Email" },
        { Id: 298012, PropertyName: "placeholder", Value: "mail@mail.com" },
        { Id: 430812, PropertyName: "value", Value: "Şifre" },
        { Id: 717714, PropertyName: "placeholder", Value: "******" },
        { Id: 387716, PropertyName: "label", Value: "Kayıt ol" },
        { Id: 593542, PropertyName: "value", Value: "Zaten üye misiniz ?" },
        { Id: 687615, PropertyName: "value", Value: "Giriş yap" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      Signup: "",
      AddUserToRole: false,
      goToNextScreenAfterSignup: "",
      NAVIGATE: "",
      isComp387716Enabled: "disabled"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("signup", "signup");
      return;
    }

    if (true) {
    }

    window.addEventListener("keydown", this.onKeyDown);

    KuikaAppManager.calculateAndSetBodyHeight("signup", "signup");
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown);
  }

  onKeyDown = (e: any) => {
    if (e.keyCode === undefined) return;
    const keyCode = e.keyCode;
    if (keyCode === 13) {
      var element = document.getElementById("387716");
      if (element && element.click) {
        element.click();
      }
    }
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("signup", "signup");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  SignupComponent_387716_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isValidEmail(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")
        ),
        null
      ),
      message: "Geçerli bir mail adresi giriniz.",
      formName: "signup_298012_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "signup_717714_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });
    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")
        ),
        null
      ),
      message: "This field is required",
      formName: "signup_388216_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.Signup = (
      (await ReactSystemFunctions.signup(
        this,
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_298012_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_717714_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_388216_value", "value", "", "", "")),
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "signup_973972_value", "value", "", "", "")),
        true,
        "",
        null,
        null
      )) as any
    ).data;
    stateVars.AddUserToRole = await ReactSystemFunctions.addUserToRole(
      "USER",
      ReactSystemFunctions.toString(this, stateVars.Signup?.username)
    );
    stateVars.tmpBoolResult = await ReactSystemFunctions.goToNextScreenAfterSignup(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  SignupComponent_687615_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "Signup",
      "Signin",
      "",
      "currentPage",
      null,
      null,
      null,
      null,
      null,
      null,
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
  }
}
