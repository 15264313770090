import { FormInstance } from "antd/lib/form";
import { Guid } from "guid-typescript";
import React from "react";
import Swal from "sweetalert2";
import { IUserInfoDto } from "../../models/dto/user-info.dto";
import { IValidationData } from "../../models/dto/validation-data";
import { UserService } from "../../services/user-service";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IUserList_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IUserList_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  GetKUsers: any[];
  GetKUsers_dummy: any[];
  GetKUsersCount: any[];
  GetKUsersCount_dummy: any[];
  DisableUser: any;
  DisableUser_dummy: any;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class UserList_ScreenBase extends React.PureComponent<IUserList_ScreenProps, any> {
  userlist_5520259_value_kuikaTableRef: React.RefObject<any>;
  userlist_606941_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "53aa6b7f-d4cf-4215-98b6-af2ae0f10232",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 651166, PropertyName: "value", Value: "Kullanıcı İşlemleri" },
        { Id: 105155, PropertyName: "label", Value: "Kullanıcı Ekle" },
        { Id: 59036, PropertyName: "placeholder", Value: "Arama" },
        { Id: 5520259, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 8047211, PropertyName: "title", Value: "E-Posta" },
        { Id: 7359078, PropertyName: "value", Value: "[datafield:username]" },
        { Id: 6147183, PropertyName: "title", Value: "Ad" },
        { Id: 8998609, PropertyName: "value", Value: "[datafield:firstname]" },
        { Id: 6995627, PropertyName: "title", Value: "Soyad" },
        { Id: 8027997, PropertyName: "value", Value: "[datafield:lastname]" },
        { Id: 590404, PropertyName: "title", Value: "Şirket" },
        { Id: 899693, PropertyName: "value", Value: "[datafield:companyname]" },
        { Id: 239095, PropertyName: "title", Value: "Rol" },
        { Id: 22534, PropertyName: "value", Value: "[datafield:rolename]" },
        { Id: 576286, PropertyName: "title", Value: "Aktif" },
        { Id: 170352, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.userlist_5520259_value_kuikaTableRef = React.createRef();
    this.userlist_606941_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      GetKUsers: [],
      GetKUsersCount: [],
      DisableUser: "",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.CheckPageAuthorization(this.state);
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("userlist", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.UserListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("userlist", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  CheckPageAuthorization = async (state) => {
    KuikaAppManager.showSpinner(this);
    if (_.isEmpty(window.userInfo)) {
      await UserService.GetUserInfoForce();
    }
    const permittedRoleNames: string[] = ["SYSTEM ADMIN", "COMPANY ADMIN"];
    const userInfo = window.userInfo as IUserInfoDto;
    const userRoleNames = userInfo.roles.map((role) => role.name);
    if (!permittedRoleNames.some((roleName) => userRoleNames.includes(roleName))) {
      await ReactSystemFunctions.goBack(this);
      KMainFunctions.displayErrorNotification("You do not have permission to access this page.");
      state.isPageVisible = false;
      KuikaAppManager.hideSpinner(this);
      return;
    }
    state.isPageVisible = true;
    KuikaAppManager.hideSpinner(this);
  };

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("userlist", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.UserListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.UserListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  UserListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.IsUserDisabled?.length > 0 ? this.state.IsUserDisabled[0]?.result : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.UserListPageInit2_,
        "UserList",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.UserListPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  UserListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "current", "", "", "")
        ),
        "number"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUsers = result?.data.getKUsers;

    stateVars.GetKUsersCount = result?.data.getKUsersCount;
    formVars.userlist_486794_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUsersCount?.length > 0 ? stateVars.GetKUsersCount[0]?.totalRecords : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5520259 = this.state.GetKUsers;
    formVars.userlist_606941_total = ReactSystemFunctions.value(
      this,
      this.state.GetKUsersCount?.length > 0 ? this.state.GetKUsersCount[0]?.totalRecords : null
    );

    formVars.userlist_486794_value = ReactSystemFunctions.toString(
      this,
      this.state.GetKUsersCount?.length > 0 ? this.state.GetKUsersCount[0]?.totalRecords : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  UserListComponent_105155_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables("UserForm", "userId", Guid.create().toString());

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "UserForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "171468",
      null,
      null,
      "False",
      "800px",
      "vh",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_59036_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "current", "", "", "")
        ),
        "number"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_59036_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUsers = result?.data.getKUsers;

    stateVars.GetKUsersCount = result?.data.getKUsersCount;
    formVars.userlist_486794_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUsersCount?.length > 0 ? stateVars.GetKUsersCount[0]?.totalRecords : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListComponent_59036_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_59036_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5520259 = this.state.GetKUsers;
    formVars.userlist_606941_total = ReactSystemFunctions.value(
      this,
      this.state.GetKUsersCount?.length > 0 ? this.state.GetKUsersCount[0]?.totalRecords : null
    );

    formVars.userlist_486794_value = ReactSystemFunctions.toString(
      this,
      this.state.GetKUsersCount?.length > 0 ? this.state.GetKUsersCount[0]?.totalRecords : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_977166_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    KuikaAppManager.prepareForNavigation();
    KuikaAppManager.addToPageInputVariables(
      "UserForm",
      "userId",
      ReactSystemFunctions.value(this, "userlist_5520259_value", ".id")
    );

    stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
      this,
      undefined,
      "UserList",
      "UserForm",
      "",
      "9924ef5f-4b84-4200-b624-8d76348c7686",
      "66587",
      null,
      null,
      "False",
      "800px",
      "573px",
      true
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_553212_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    Swal.fire({
      title: ReactSystemFunctions.translateCustomActions(
        this.ml,
        "63a7d744_7623_5acf_d5db_4fdb52282218_confirmation",
        this.defaultML,
        "Silmek istediğinize emin misiniz?"
      ),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Onayla"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Confirmer"
          : "Confirm",
      cancelButtonText:
        localStorage.getItem("ml") === "tr_TR"
          ? "Vazgeç"
          : localStorage.getItem("ml") === "fr_FR"
          ? "Annuler"
          : "Cancel"
    }).then(async (result) => {
      if (result.isConfirmed) {
        KuikaAppManager.showSpinner(this);

        stateVars.DisableUser = await ReactSystemFunctions.disableUser(
          ReactSystemFunctions.value(this, "userlist_5520259_value", ".userName")
        );

        this.props.form.setFieldsValue(formVars);
        this.setState(stateVars, () => {
          this.UserListComponent_553212_onClick1_();
        });
      }
    });

    return isErrorOccurred;
  };
  UserListComponent_553212_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "current", "", "", "")
        ),
        "number"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      UserName_1: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_553212_onClick1_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUsers = result?.data.getKUsers;

    stateVars.GetKUsersCount = result?.data.getKUsersCount;
    formVars.userlist_486794_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetKUsersCount?.length > 0 ? stateVars.GetKUsersCount[0]?.totalRecords : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListComponent_553212_onClick2_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_553212_onClick2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5520259 = this.state.GetKUsers;
    formVars.userlist_606941_total = ReactSystemFunctions.value(
      this,
      this.state.GetKUsersCount?.length > 0 ? this.state.GetKUsersCount[0]?.totalRecords : null
    );

    formVars.userlist_486794_value = ReactSystemFunctions.toString(
      this,
      this.state.GetKUsersCount?.length > 0 ? this.state.GetKUsersCount[0]?.totalRecords : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  UserListComponent_606941_onChange = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      SearchQuery_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, ReactSystemFunctions.value(this, "userlist_59036_value", "value", "", "", "")),
        "string"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "current", "", "", "")
        ),
        "number"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "userlist_606941_value", "pageSize", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "UserList/UserListComponent_606941_onChange", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetKUsers = result?.data.getKUsers;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.UserListComponent_606941_onChange1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  UserListComponent_606941_onChange1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_5520259 = this.state.GetKUsers;

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [, 171468, 66587] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.UserListPageInit();
    }
  }
}
