import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IRequirement_Form_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IRequirement_Form_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  CreateRequirement: number;
  CreateRequirement_dummy: number;
  GoBack: any;
  GoBack_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetProject: any[];
  GetProject_dummy: any[];
  GetProjectRequirement: any[];
  GetProjectRequirement_dummy: any[];
  isComp707428Visible: "visible" | "hidden";
  isComp554998Visible: "visible" | "hidden";
  isComp706367Visible: "visible" | "hidden";
  isComp145559Visible: "visible" | "hidden";
  isComp994354Visible: "visible" | "hidden";
  isComp485150Visible: "visible" | "hidden";
  isComp300462Visible: "visible" | "hidden";
  isComp858854Visible: "visible" | "hidden";
  isComp298228Visible: "visible" | "hidden";
  isComp854828Visible: "visible" | "hidden";
  isComp422968Visible: "visible" | "hidden";
  isComp615350Visible: "visible" | "hidden";
  isComp830376Visible: "visible" | "hidden";
  isComp775747Visible: "visible" | "hidden";
  isComp127451Visible: "visible" | "hidden";
  isComp326834Visible: "visible" | "hidden";
  isComp747423Visible: "visible" | "hidden";
  isComp108091Visible: "visible" | "hidden";
}

export class Requirement_Form_ScreenBase extends React.PureComponent<IRequirement_Form_ScreenProps, any> {
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "aaae42ad-0487-4198-a795-4270d6986f3f",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 839541, PropertyName: "label", Value: "Kaydet" },
        { Id: 474183, PropertyName: "label", Value: "İptal" },
        { Id: 432538, PropertyName: "value", Value: "Gereksinim Detay" },
        { Id: 885244, PropertyName: "value", Value: "Kategori" },
        { Id: 206702, PropertyName: "value", Value: "Gereksinim" },
        { Id: 670624, PropertyName: "value", Value: "Interaction" },
        { Id: 524113, PropertyName: "value", Value: "Communication" },
        { Id: 331826, PropertyName: "value", Value: "Process" },
        { Id: 706244, PropertyName: "value", Value: "Toplam EP" },
        { Id: 600489, PropertyName: "value", Value: "Entry" },
        { Id: 221843, PropertyName: "value", Value: "Read" },
        { Id: 408893, PropertyName: "value", Value: "Write" },
        { Id: 985550, PropertyName: "value", Value: "Exit" },
        { Id: 187555, PropertyName: "value", Value: "Toplam CFP" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      CreateRequirement: 0,
      GoBack: "",
      MyContext: [],
      GetProject: [],
      GetProjectRequirement: [],
      isComp707428Visible: "hidden",
      isComp554998Visible: "hidden",
      isComp706367Visible: "hidden",
      isComp145559Visible: "hidden",
      isComp994354Visible: "hidden",
      isComp485150Visible: "hidden",
      isComp300462Visible: "hidden",
      isComp858854Visible: "hidden",
      isComp298228Visible: "hidden",
      isComp854828Visible: "hidden",
      isComp422968Visible: "hidden",
      isComp615350Visible: "hidden",
      isComp830376Visible: "hidden",
      isComp775747Visible: "hidden",
      isComp127451Visible: "hidden",
      isComp326834Visible: "hidden",
      isComp747423Visible: "hidden",
      isComp108091Visible: "hidden"
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("requirement_form", "requirement_form");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (true) {
      await this.Requirement_FormPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("requirement_form", "requirement_form");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("requirement_form", "requirement_form");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Requirement_FormPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({
      requirement_form_303031_value: this.state.GetProjectRequirement?.at?.(0)?.category ?? undefined,
      requirement_form_440665_value: this.state.GetProjectRequirement?.at?.(0)?.requirement ?? undefined,
      requirement_form_554998_value: this.state.GetProjectRequirement?.at?.(0)?.interaction ?? undefined,
      requirement_form_145559_value: this.state.GetProjectRequirement?.at?.(0)?.communication ?? undefined,
      requirement_form_485150_value: this.state.GetProjectRequirement?.at?.(0)?.eventProcess ?? undefined,
      requirement_form_858854_value: this.state.GetProjectRequirement?.at?.(0)?.totalEP ?? undefined,
      requirement_form_854828_value: this.state.GetProjectRequirement?.at?.(0)?.entry ?? undefined,
      requirement_form_615350_value: this.state.GetProjectRequirement?.at?.(0)?.cosmicRead ?? undefined,
      requirement_form_775747_value: this.state.GetProjectRequirement?.at?.(0)?.cosmicWrite ?? undefined,
      requirement_form_326834_value: this.state.GetProjectRequirement?.at?.(0)?.cosmicExit ?? undefined,
      requirement_form_108091_value: this.state.GetProjectRequirement?.at?.(0)?.totalCFP ?? undefined
    });
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  Requirement_FormPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      ProjectID_1: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      ReqID_2: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RequirementID ?? this.props.screenInputs.requirementid,
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Requirement_Form/Requirement_FormPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetProject = result?.data.getProject;
    stateVars.isComp707428Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp554998Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp706367Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp145559Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp994354Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp485150Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300462Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp858854Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp298228Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp854828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422968Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicRead : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp615350Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicRead : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830376Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp775747Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicExit : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp326834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.cosmicExit : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp747423Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp108091Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, stateVars.GetProject?.length > 0 ? stateVars.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.GetProjectRequirement = result?.data.getProjectRequirement;
    formVars.requirement_form_303031_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.category : null
    );
    formVars.requirement_form_440665_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.requirement : null
    );
    formVars.requirement_form_554998_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.interaction : null
    );
    formVars.requirement_form_145559_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.communication : null
    );
    formVars.requirement_form_485150_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.eventProcess : null
    );
    formVars.requirement_form_858854_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.totalEP : null
    );
    formVars.requirement_form_854828_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.entry : null
    );
    formVars.requirement_form_615350_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.cosmicRead : null
    );
    formVars.requirement_form_775747_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.cosmicWrite : null
    );
    formVars.requirement_form_326834_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.cosmicExit : null
    );
    formVars.requirement_form_108091_value = ReactSystemFunctions.value(
      this,
      stateVars.GetProjectRequirement?.length > 0 ? stateVars.GetProjectRequirement[0]?.totalCFP : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_FormPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_FormPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    formVars.requirement_form_303031_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.category : null
    );

    formVars.requirement_form_440665_value = ReactSystemFunctions.toString(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.requirement : null
    );

    stateVars.isComp707428Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_554998_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.interaction : null
    );

    stateVars.isComp554998Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.interaction : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp706367Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_145559_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.communication : null
    );

    stateVars.isComp145559Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.communication : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp994354Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_485150_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.eventProcess : null
    );

    stateVars.isComp485150Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.eventProcess : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp300462Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_858854_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.totalEP : null
    );

    stateVars.isComp858854Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalEP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp298228Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_854828_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.entry : null
    );

    stateVars.isComp854828Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.entry : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp422968Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicRead : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_615350_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.cosmicRead : null
    );

    stateVars.isComp615350Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicRead : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp830376Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_775747_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.cosmicWrite : null
    );

    stateVars.isComp775747Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicWrite : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp127451Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicExit : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_326834_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.cosmicExit : null
    );

    stateVars.isComp326834Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.cosmicExit : null
        ),
        true
      ) === true
        ? "visible"
        : "hidden";
    stateVars.isComp747423Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";
    formVars.requirement_form_108091_value = ReactSystemFunctions.value(
      this,
      this.state.GetProjectRequirement?.length > 0 ? this.state.GetProjectRequirement[0]?.totalCFP : null
    );

    stateVars.isComp108091Visible =
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(this, this.state.GetProject?.length > 0 ? this.state.GetProject[0]?.totalCFP : null),
        true
      ) === true
        ? "visible"
        : "hidden";

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Requirement_FormComponent_839541_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    validations.push({
      isValid: ReactSystemFunctions.isNotEmpty(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requirement_form_440665_value", "value", "", "", "")
        ),
        null
      ),
      message: "Gereksinim Boş Bırakılamaz",
      formName: "requirement_form_440665_value",
      condition: true
    } as IValidationData);

    validations.forEach((validation) => {
      if (validation.message === "Geçerli bir mail adresi giriniz.") {
        validation.message = ReactSystemFunctions.getEmailValidationMessage();
      }
    });

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      RequirementID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.RequirementID ?? this.props.screenInputs.requirementid,
        "Guid"
      ),
      Category_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requirement_form_303031_value", "value", "", "", "")
        ),
        "string"
      ),
      Communication_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_145559_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicExit_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_326834_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicRead_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_615350_value", "value", "", "", "")
        ),
        "number"
      ),
      CosmicWrite_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_775747_value", "value", "", "", "")
        ),
        "number"
      ),
      Entry_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_854828_value", "value", "", "", "")
        ),
        "number"
      ),
      EventProcess_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_485150_value", "value", "", "", "")
        ),
        "number"
      ),
      Interaction_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_554998_value", "value", "", "", "")
        ),
        "number"
      ),
      LastUpdateUserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      ProjectID_0: ReactSystemFunctions.convertToTypeByName(
        this.props.screenInputs.ProjectID ?? this.props.screenInputs.projectid,
        "Guid"
      ),
      Requirement_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(
          this,
          ReactSystemFunctions.value(this, "requirement_form_440665_value", "value", "", "", "")
        ),
        "string"
      ),
      TotalCFP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_108091_value", "value", "", "", "")
        ),
        "number"
      ),
      TotalEP_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "requirement_form_858854_value", "value", "", "", "")
        ),
        "number"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(
      this,
      "Requirement_Form/Requirement_FormComponent_839541_onClick",
      localVar
    );
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.CreateRequirement = result?.data.createRequirement;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Requirement_FormComponent_839541_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Requirement_FormComponent_839541_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_FormComponent_474183_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Requirement_FormComponent_120216_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.showSpinner(this);

    stateVars.GoBack = await ReactSystemFunctions.goBack(this);

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
  }
}
