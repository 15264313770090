import { FormInstance } from "antd/lib/form";
import React from "react";
import { IValidationData } from "../../models/dto/validation-data";
import { KContext } from "../../shared/hoc/with-context";
import { KNavigator } from "../../shared/hoc/with-history";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { KMainFunctions } from "../../shared/utilty/main-functions";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";

declare let window: any;

interface IQueue_List_ScreenProps {
  navigator: KNavigator;
  form: FormInstance<any>;
  googleLogin: any;
  screenInputs: any;
  context: KContext;
  initialValues: any;
  children: any;
}

interface IQueue_List_ScreenState {
  isSpinnerVisible: boolean;
  isPageVisible: boolean;
  isLeftMenuVisible: boolean;
  spinnerCount: number;
  selectedLanguage: string;
  isPageInitLoaded: false;
  isPageRecurringLoaded: false;
  IsUserDisabled: any[];
  IsUserDisabled_dummy: any[];
  NAVIGATE: any;
  NAVIGATE_dummy: any;
  MyContext: any[];
  MyContext_dummy: any[];
  GetJobsCount: any[];
  GetJobsCount_dummy: any[];
  GetJobsQueue: any[];
  GetJobsQueue_dummy: any[];
  GetConfigFromFile: any;
  GetConfigFromFile_dummy: any;
  Cancel: any;
  Cancel_dummy: any;

  isMasterPageInitLoaded?: boolean;
  isMasterPageRecurringLoaded?: boolean;
}

export class Queue_List_ScreenBase extends React.PureComponent<IQueue_List_ScreenProps, any> {
  queue_list_795226_value_kuikaTableRef: React.RefObject<any>;
  queue_list_658618_value_kuikaPaginationRef: React.RefObject<any>;
  ml = [
    {
      Id: "acc06697-d07d-f01d-0205-963c616cb1d3",
      Name: "tr_TR",
      ShortName: "Turkish",
      IsDefault: true,
      LanguagePhrases: []
    },
    { Id: "ae3cf4c3-5f91-4ffc-8394-ad05bab33571", Name: "en_US", ShortName: "English", LanguagePhrases: [] },
    {
      Id: "8f055550-8a13-4025-8f12-ae4cfbcaabcb",
      Name: "FixedValues",
      ShortName: "FixedValues",
      LanguagePhrases: [
        { Id: 958134, PropertyName: "value", Value: "Kuyruktaki İşler" },
        { Id: 842398, PropertyName: "label", Value: "Yenile" },
        { Id: 795226, PropertyName: "nodatafoundmessage", Value: "No data found." },
        { Id: 943865, PropertyName: "title", Value: "İşlem" },
        { Id: 809316, PropertyName: "title", Value: "Model Adı" },
        { Id: 979789, PropertyName: "value", Value: "Büyüklük Kestirim Modeli" },
        { Id: 143445, PropertyName: "title", Value: "Baz Model" },
        { Id: 112340, PropertyName: "value", Value: "[datafield:modelname]" },
        { Id: 228361, PropertyName: "title", Value: "Proje Kodu" },
        { Id: 884182, PropertyName: "value", Value: "[datafield:projectcode]" },
        { Id: 758027, PropertyName: "title", Value: "Proje Adı" },
        { Id: 922220, PropertyName: "value", Value: "[datafield:projectname]" },
        { Id: 39612, PropertyName: "title", Value: "Durum" },
        { Id: 789441, PropertyName: "value", Value: "[datafield:statusname]" },
        { Id: 176956, PropertyName: "title", Value: "Başlangıç Tarihi" },
        { Id: 806157, PropertyName: "value", Value: "[datafield:starttime]" },
        { Id: 840887, PropertyName: "title", Value: "Bitiş Tarihi" },
        { Id: 41486, PropertyName: "value", Value: "[datafield:endtime]" },
        { Id: 372216, PropertyName: "title", Value: "Süre" },
        { Id: 186017, PropertyName: "value", Value: "[datafield:elapsedtime]" },
        { Id: 973484, PropertyName: "value", Value: "Kayıt" }
      ]
    }
  ];
  defaultML = "tr_TR";
  screenHash = "";

  constructor(props) {
    super(props);
    this.queue_list_795226_value_kuikaTableRef = React.createRef();
    this.queue_list_658618_value_kuikaPaginationRef = React.createRef();

    this.state = {
      isSpinnerVisible: false,
      isPageVisible: false,
      isLeftMenuVisible: false,
      spinnerCount: 0,
      selectedLanguage: "",
      isPageInitLoaded: false,
      isPageRecurringLoaded: false,
      IsUserDisabled: [],
      NAVIGATE: "",
      MyContext: [],
      GetJobsCount: [],
      GetJobsQueue: [],
      GetConfigFromFile: "",
      Cancel: "",

      isMasterPageInitLoaded: false,
      isMasterPageRecurringLoaded: false
    };

    this.state.isPageVisible = true;
  }

  async componentDidMount() {
    if (KuikaAppManager.isPageInitPrevented()) {
      KuikaAppManager.calculateAndSetBodyHeight("queue_list", "siskonmaster_screen");
      return;
    }

    ReactSystemFunctions.validateToken();
    if (false) {
      await this.Queue_ListPageInit();
    }

    KuikaAppManager.calculateAndSetBodyHeight("queue_list", "siskonmaster_screen");
  }

  componentWillUnmount() {}

  componentDidUpdate = async (prevProps, prevState) => {
    KuikaAppManager.calculateAndSetBodyHeight("queue_list", "siskonmaster_screen");
    if (prevProps?.location?.search !== (this.props as any)?.location?.search) {
      await this.Queue_ListPageInit();
    }

    if (
      prevState.isMasterPageInitLoaded !== this.state.isMasterPageInitLoaded &&
      this.state.isMasterPageInitLoaded === true
    ) {
      await this.Queue_ListPageInit();
    }
  };

  fillFormInitValues() {
    this.props.form.setFieldsValue({});
  }

  setPageInitLoaded = (value: boolean) => {
    this.setState({ isPageInitLoaded: value });
  };

  setPageRecurringLoaded = (value: boolean) => {
    this.setState({ isPageRecurringLoaded: value });
  };

  setMasterPageInitLoaded = (value: boolean) => {
    this.setState({ isMasterPageInitLoaded: value });
  };

  setMasterPageRecurringLoaded = (value: boolean) => {
    this.setState({ isMasterPageRecurringLoaded: value });
  };

  Queue_ListPageInit = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string")
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Queue_List/Queue_ListPageInit", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.IsUserDisabled = result?.data.isUserDisabled;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Queue_ListPageInit1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Queue_ListPageInit1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    if (
      ReactSystemFunctions.isEqualTo(
        ReactSystemFunctions.value(
          this,
          this.state.IsUserDisabled?.length > 0 ? this.state.IsUserDisabled[0]?.result : null
        ),
        "1"
      )
    ) {
      KuikaAppManager.prepareForNavigation();

      stateVars.NAVIGATE = await ReactSystemFunctions.navigate(
        this,
        this.Queue_ListPageInit2_,
        "Queue_List",
        "DisabledAccount",
        "",
        "currentPage",
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );

      this.props.form.setFieldsValue(formVars);
      this.setState(stateVars);
    } else {
      this.setState(stateVars, () => {
        this.Queue_ListPageInit2_();
      });
    }

    return isErrorOccurred;
  };
  Queue_ListPageInit2_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    let localVar = {
      UserName_0: ReactSystemFunctions.convertToTypeByName(ReactSystemFunctions.getMyUsername(), "string"),
      PageSize_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "queue_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_2: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "queue_list_658618_value", "current", "", "", "")
        ),
        "number"
      )
    };

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Queue_List/Queue_ListPageInit2_", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.MyContext = result?.data.myContext;
    stateVars.GetJobsCount = result?.data.getJobsCount;
    formVars.queue_list_671699_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetJobsCount?.length > 0 ? stateVars.GetJobsCount[0]?.jobCount : null
    );
    stateVars.GetJobsQueue = result?.data.getJobsQueue;

    stateVars.GetConfigFromFile = result?.data.getConfigFromFile;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Queue_ListPageInit3_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Queue_ListPageInit3_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetJobsQueue;
    formVars.queue_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetJobsCount?.length > 0 ? this.state.GetJobsCount[0]?.jobCount : null
    );

    formVars.queue_list_671699_value = ReactSystemFunctions.toString(
      this,
      this.state.GetJobsCount?.length > 0 ? this.state.GetJobsCount[0]?.jobCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    return isErrorOccurred;
  };

  Queue_ListComponent_842398_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      CompanyID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      ),
      PageSize_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "queue_list_658618_value", "pageSize", "", "", "")
        ),
        "number"
      ),
      PageNo_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(
          this,
          ReactSystemFunctions.value(this, "queue_list_658618_value", "current", "", "", "")
        ),
        "number"
      ),
      CompanyID_1: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.companyId : null),
        "Guid"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Queue_List/Queue_ListComponent_842398_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.GetJobsQueue = result?.data.getJobsQueue;

    stateVars.GetJobsCount = result?.data.getJobsCount;
    formVars.queue_list_671699_value = ReactSystemFunctions.toString(
      this,
      stateVars.GetJobsCount?.length > 0 ? stateVars.GetJobsCount[0]?.jobCount : null
    );
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars, () => {
      this.Queue_ListComponent_842398_onClick1_();
    });

    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };
  Queue_ListComponent_842398_onClick1_ = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;

    stateVars.dataSource_795226 = this.state.GetJobsQueue;
    formVars.queue_list_658618_total = ReactSystemFunctions.value(
      this,
      this.state.GetJobsCount?.length > 0 ? this.state.GetJobsCount[0]?.jobCount : null
    );

    formVars.queue_list_671699_value = ReactSystemFunctions.toString(
      this,
      this.state.GetJobsCount?.length > 0 ? this.state.GetJobsCount[0]?.jobCount : null
    );

    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    return isErrorOccurred;
  };

  Queue_ListComponent_252302_onClick = async () => {
    let stateVars = {} as any;
    let formVars = {} as any;
    let validations = [] as IValidationData[];
    let isErrorOccurred = false;

    if (KuikaAppManager.handleValidations(this, validations)) return true;
    KuikaAppManager.increaseServerRequestCount();

    let localVar = {
      JobID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, "queue_list_795226_value", "jobID"),
        "Guid"
      ),
      UserID_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.value(this, this.state.MyContext?.length > 0 ? this.state.MyContext[0]?.kUserID : null),
        "Guid"
      ),
      CancelPath_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetConfigFromFile?.cancelPath),
        "string"
      ),
      BaseUrl_0: ReactSystemFunctions.convertToTypeByName(
        ReactSystemFunctions.toString(this, this.state.GetConfigFromFile?.baseUrl),
        "string"
      )
    };

    KuikaAppManager.showSpinner(this);

    if (true) {
      KuikaAppManager.showSpinner(this);
    }
    let result = await KuikaAppManager.ApiRequest(this, "Queue_List/Queue_ListComponent_252302_onClick", localVar);
    if (!KMainFunctions.IsResStatus2XX(result)) return true;

    stateVars.Cancel = result?.data.cancel;
    this.props.form.setFieldsValue(formVars);
    this.setState(stateVars);
    KuikaAppManager.hideSpinner(this);

    this.setPageInitLoaded(true);
    KuikaAppManager.hideSpinner(this);
    return isErrorOccurred;
  };

  async callCallbackFunction(diId) {
    let isErrorOccurred = false;
    let pageInitCallerNavDiIDs = [] as number[];
    if (window.kuika?.serverRequestCount > 0 && pageInitCallerNavDiIDs.indexOf(diId) > -1) {
      await this.Queue_ListPageInit();
    }
  }
}
